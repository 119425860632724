import React, { Component } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import { LoadingIndicatorStyle as style } from '../../styles/common/LoadingIndicator';

class LoadingIndicator extends Component {
  render () {
    return (
      <View style={style.containerStyle}>
        <ActivityIndicator
          size="large"
          style={style.indicatorStyle}
        />
        <Text style={style.loadingTextStyle}>
          {this.props.message}
        </Text>
      </View>
    );
  }
}

export { LoadingIndicator };
