export const EMAIL_CHANGED = 'email_changed';
export const PASSWORD_CHANGED = 'password_changed';
export const IS_LOADING = 'is_loading';
export const LOGIN_USER_SUCCESS = 'login_success';
export const LOGIN_USER_UNAUTHORIZED = 'login_unauthorized';
export const LOGIN_USER_FAILED = 'login_failed';
export const LOGIN_EMPTY_FIELDS = 'login_empty_fields';
export const LOAD_FAILED = 'load_failed';
export const LOGIN_RECOVER_PASSWORD = 'login_recover_password';
export const LOADING_TOKEN = 'loading_token';
export const SESSION_EXPIRED = 'session_expired';