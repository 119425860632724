import React, { Component } from 'react';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { View } from 'react-native';
import { Provider } from 'react-redux';
import reducers from './reducers';
import { AppStyle as styles } from './styles';
import Application from './features/common/application/Application';
import Header from './features/common/header/Header';
import SectionDrawer from './features/section_drawer/SectionDrawer';
import AccountActions from './features/actions/AccountActions';

class App extends Component {

  render() {
    return (
      <Provider store={createStore(reducers, {}, applyMiddleware(ReduxThunk))}>
        <Application style={styles.applicationBackground}>
          <Header />
          <View style={styles.mainContainerStyle}>
            <SectionDrawer />
          </View>
          <AccountActions />
        </Application>
      </Provider>
    );
  }
}

export default App;
