import * as colors from '../../../styles/colors';

export const ActionStyle = {
  accountContainerStyle: {
    position: 'absolute',
    top: 37,
    right: -70,
    paddingEnd: 5,
    flexDirection: 'column',
    alignSelf: 'flex-end',
    backgroundColor: colors.WHITE,
    shadowColor: colors.BLACK,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.2,
    shadowRadius: 5
  },
  userContainerStyle: {
    position: 'absolute',
    top: 37,
    right: 1,
    flexDirection: 'column',
    alignSelf: 'flex-end',
    padding: 10,
    backgroundColor: colors.WHITE,
    shadowColor: colors.BLACK,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.2,
    shadowRadius: 5
  },
  imageStyle: {
    width: 25,
    height: 25,
    marginStart: 15
  },
  textStyle: {
    fontSize: 15,
    color: colors.OLD_WHITE,
    textShadowColor: colors.BLACK,
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    textOverflow: 'ellipsis',
    whiteSpace:'nowrap',
    overflow: 'hidden',
    alignSelf:'center'
  },
  textContainerStyle: {
    height: 25,
    width: '25vw',
    flexDirection: 'row-reverse',
  },
};