import React, { Component } from 'react';
import { View } from 'react-native';
import { EmptyAdaptiveCardStyle as style } from '../../styles/common';
import { 
  APPLICATION_RENDERING_MODE_MOBILE as mobile ,
  APPLICATION_RENDERING_MODE_SCREEN as screen
} from '../../features/common/application/redux/constants';
import {
  MEDIUM,
  SMALL,
  LARGE,
  CARD_SIZE_LARGE,
  CARD_SIZE_MEDIUM,
  CARD_SIZE_SMALL,
  BASE_WIDTH,
} from './constants';



class EmptyAdaptiveCard extends Component {
  getRenderingStyle() {
    if (this.props.renderingMode === mobile) {
      return style.mobileContainerStyle;
    } else if (this.props.renderingMode === screen) {
      return this.getCardStyle(style.containerStyle);
    }
  }

  getCardStyle(baseStyle) {
    switch (this.props.size) {
      case LARGE:
        return { ...baseStyle, width: BASE_WIDTH + CARD_SIZE_LARGE };
      case MEDIUM:
        return { ...baseStyle, width: BASE_WIDTH + CARD_SIZE_MEDIUM };
      case SMALL:
          return { ...baseStyle, width: BASE_WIDTH + CARD_SIZE_SMALL };
      default:
        return { ...baseStyle, width: BASE_WIDTH + CARD_SIZE_LARGE };
    }
  }

  render() {
    return (
      <View style={this.getRenderingStyle()}>
        {this.props.children}
      </View>
    );
  }
}

export { EmptyAdaptiveCard };
