import React, { Component } from 'react';
import { View } from 'react-native';
import {
  APPLICATION_RENDERING_MODE_MOBILE,
  APPLICATION_RENDERING_MODE_SCREEN
} from '../../features/common/application/redux/constants';
import { RowContainerStyle as style } from '../../styles/common';

class RowContainer extends Component {

  getRenderingStyle() {
    if (this.props.renderingMode === APPLICATION_RENDERING_MODE_MOBILE) {
      return style.mobileContainerStyle;
    } else if (this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN) {
      return style.containerStyle;
    }
  }

  render() {
    return (
      <View style={this.getRenderingStyle()}>
        {this.props.children}
      </View>
    );
  }
}

export { RowContainer };
