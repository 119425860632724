import { INITIAL_STATE } from "./initialState";
import { 
  PERFORMANCE_SWITCH_CHART_TYPE, 
  PERFORMANCE_SWITCH_CURRENCY, 
  PERFORMANCE_SWITCH_ACCOUNT, 
  PERFORMANCE_DATA_UPDATE,
  PERFORMANCE_SWITCH_DATE,
  PERFORMANCE_DATA_UPDATE_FAILURE,
  PERFORMANCE_ACCUMULATED_CHART_DATA,
  PERFORMANCE_MONTHLY_CHART_DATA,
  PERFORMANCE_PERFORMANCE_CHART_DATA
} from "./types";
import { PERFORMANCE_START_DATE, PERFORMANCE_END_DATE } from "./constants";

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case PERFORMANCE_SWITCH_CHART_TYPE:
      return { ...state, chartType: action.payload };
    case PERFORMANCE_SWITCH_CURRENCY:
      return { ...state, currency: action.payload, data: null, loading: true };
    case PERFORMANCE_SWITCH_ACCOUNT:
      return { ...state, data: null, loading: true, selectedAccount: action.payload }
    case PERFORMANCE_DATA_UPDATE:
      return { ...state, data: action.payload, loading: false, changeRatio: action.payload.changeRatio };
    case PERFORMANCE_SWITCH_DATE + PERFORMANCE_START_DATE:
      return { ...state, startDate: action.payload, data: null, loading: true };
    case PERFORMANCE_SWITCH_DATE + PERFORMANCE_END_DATE:
      return { ...state, endDate: action.payload, data: null, loading: true };
    case PERFORMANCE_DATA_UPDATE_FAILURE:
      return { ...state, data: null, loading: false };
    case PERFORMANCE_ACCUMULATED_CHART_DATA:
      return { ...state, accumulatedChartData: action.payload };
    case PERFORMANCE_MONTHLY_CHART_DATA:
      return { ...state, monthlyChartData: action.payload };
    case PERFORMANCE_PERFORMANCE_CHART_DATA:
      return { ...state, performanceChartData: action.payload };
    default: 
    return state;
  }
};
