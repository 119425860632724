import {
  EMAIL_CHANGED,
  ID_CHANGED,
  PASSWORD_RECOVERY_ERROR,
  PASSWORD_RECOVERY_LOADING,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_CANCEL,
  PASSWORD_RECOVERY_EMPTY_FIELDS
} from "./types";
import { INITIAL_STATE } from './initialState';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EMAIL_CHANGED:
      return { ...state, email: action.payload };
    case ID_CHANGED:
      return { ...state, id: action.payload };
    case PASSWORD_RECOVERY_SUCCESS:
      return { ...state, loading: false, successMessage: action.payload };
    case PASSWORD_RECOVERY_CANCEL:
      return { ...state, loading: false, successMessage: '', error: '', email: '', id: '' };
    case PASSWORD_RECOVERY_EMPTY_FIELDS:
      return { ...state, loading: false, successMessage: '', error: action.payload };
    case PASSWORD_RECOVERY_LOADING:
      return { ...state, loading: true, error: '' };
    case PASSWORD_RECOVERY_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
