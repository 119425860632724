import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { 
  MIN_MOBILE_WIDTH,
  APPLICATION_RENDERING_MODE_MOBILE,
  APPLICATION_RENDERING_MODE_SCREEN
} from './redux/constants';
import { switchRenderingMode } from './redux/actions';
import { loadToken, loadingToken } from '../../login/redux/actions';
import { Spinner } from '../../../components/common';

class Application extends Component {

  componentWillMount() {
    this.props.loadingToken(true);
    this.props.loadToken();
  }

  shouldSwitchToMobile = ({nativeEvent}) => {
    if (nativeEvent.layout.width <= MIN_MOBILE_WIDTH &&
        this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN) {
      this.props.switchRenderingMode(APPLICATION_RENDERING_MODE_MOBILE);
    } else if (nativeEvent.layout.width > MIN_MOBILE_WIDTH &&
        this.props.renderingMode === APPLICATION_RENDERING_MODE_MOBILE) {
      this.props.switchRenderingMode(APPLICATION_RENDERING_MODE_SCREEN);
    }
  }

  renderContent() {
    if (this.props.isLoadingToken) {
      return <Spinner />
    }
    else {
      return this.props.children;
    }
  }

  render() {
    return (
      <View 
        onLayout={this.shouldSwitchToMobile.bind(this)} 
        style={this.props.style}
      >
        {this.renderContent()}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    renderingMode: state.application.renderingMode,
    isLoadingToken: state.login.loadingToken
  }
};

export default connect(mapStateToProps, { switchRenderingMode, loadToken, loadingToken })(Application)