import React, { Component } from 'react';
import { View, FlatList, TouchableWithoutFeedback, Image } from 'react-native';
import { connect } from 'react-redux';
import { ActionStyle as style } from './style/Action';
import { showUserActionPane } from './redux/actions';
import UserActionItem from './UserActionItem';

class UserActionPane extends Component {

  onShowAction() {
    this.props.showUserActionPane();
  }

  renderItem(actionItem) {
    return <UserActionItem item={actionItem.item}/>;
  }

  renderList() {
    if (this.props.show) {
      return (
        <View style={style.userContainerStyle}>
          <FlatList
            data={this.props.userActions}
            renderItem={this.renderItem}
            keyExtractor={(actionItem) => actionItem.id.toString()}
          />
        </View>
      );
    } else {
      return <View/>
    }
  }

  render() {
    return (
      <View>
        <TouchableWithoutFeedback 
          onPress={this.onShowAction.bind(this)}
        >
          <Image
            source={require('./assets/Usuario.svg')}
            style={style.imageStyle}
          />
        </TouchableWithoutFeedback>
          {this.renderList()}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    show: state.user.showUserPane,
    userActions: state.actionItems
  }
}

export default connect(mapStateToProps, { showUserActionPane })(UserActionPane);
