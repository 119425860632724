import React, { Component } from 'react';
import { Picker } from 'react-native';
import { SelectorStyle as style } from '../../styles/common';

class Selector extends Component {
  render() {
    return (
        <Picker
          selectedValue={this.props.selectedValue}
          onValueChange={this.props.onValueChange}
          style={style.pickerStyle}
          itemStyle={style.itemStyle}
        >
          {this.props.children}
        </Picker>
    );
  }
}

export { Selector };
