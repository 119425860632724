import * as colors from '../colors';

export const CardStyle = {
  containerStyle: {
    width: '50%',
    alignSelf: 'center',
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.SYC_LIGHT_GRAY,
    borderBottomWidth: 0,
    shadowColor: colors.BLACK,
    shadowRadius: 5,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.2,
    elevation: 1,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 10,
    opacity: 0.95
  },
  mobileContainerStyle: {
    width: 'calc(100vw - 20px)', 
    alignSelf: 'center',
    opacity: 0.95
  }
}
