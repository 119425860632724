import React, { Component } from 'react';
import { View } from 'react-native';
import { TextLineStyle as style } from '../../styles/common';

class TextLine extends Component {
  render() {
    return (
      <View style={style.containerStyle}>
        {this.props.children}
      </View>
    );
  }
}

export { TextLine };
