import React, { Component } from 'react';
import { View, ImageBackground } from 'react-native';
import { LoginFormStyle as style } from './style/LoginForm';
import { connect } from 'react-redux';
import { LOGIN_LOGIN_FIELDS, LOGIN_PASSWORD_RECOVERY_FIELDS } from './redux/constants';
import {
  APPLICATION_RENDERING_MODE_MOBILE,
  APPLICATION_RENDERING_MODE_SCREEN,
  MIN_MOBILE_WIDTH
} from '../../features/common/application/redux/constants';
import { switchRenderingMode } from '../../features/common/application/redux/actions';
import LoginFields from './LoginFields';
import PasswordRecoveryFields from '../password_recovery/PasswordRecoveryFields';

class LoginForm extends Component {

  getFields() {
    if (this.props.fields === LOGIN_LOGIN_FIELDS) {
      return <LoginFields />
    } else if (this.props.fields === LOGIN_PASSWORD_RECOVERY_FIELDS) {
      return <PasswordRecoveryFields />
    }
  }
  shouldSwitchToMobile = ({ nativeEvent }) => {
    if (nativeEvent.layout.width <= MIN_MOBILE_WIDTH &&
      this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN) {
      this.props.switchRenderingMode(APPLICATION_RENDERING_MODE_MOBILE);
    } else if (nativeEvent.layout.width > MIN_MOBILE_WIDTH &&
      this.props.renderingMode === APPLICATION_RENDERING_MODE_MOBILE) {
      this.props.switchRenderingMode(APPLICATION_RENDERING_MODE_SCREEN);
    }
  }

  render() {
    return (
      <View style={style.formStyle}
        onLayout={this.shouldSwitchToMobile.bind(this)} >
        <ImageBackground
          source={require('./assets/background.JPG')}
          imageStyle={style.imageStyle}
          style={style.containerStyle}
        >
          {this.getFields()}
        </ImageBackground>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    fields: state.login.fields,
    renderingMode: state.application.renderingMode,
  };
};


export default connect(mapStateToProps, { switchRenderingMode })(LoginForm);
