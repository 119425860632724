import { retrieve } from "../../../storage/Storage";
import { TOKEN } from "../../../storage/keys";
import { EXTERNAL_AUTH_SUCCESS } from "./types";
import { dispatchLogoutProcess } from "../../actions/redux/actions";

export const requestAccess = (externalUrl) => {
  return (dispatch) => {
    retrieve(TOKEN)
    .then(token => fetch(externalUrl, {
      method: 'GET', 
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Auth-Token': token
      }
    }))
    .then(response => dispatchResponse(dispatch, response))
  };
};

const dispatchResponse = (dispatch, response) => {
  if (response.status === 200) {
    response.json()
      .then(data => onSuccess(dispatch, data));
  } else if (response.status === 401) {
    dispatchLogoutProcess(dispatch);
  }
};

const onSuccess = (dispatch, data) => {
  dispatch({
    type: EXTERNAL_AUTH_SUCCESS,
    payload: data
  });
};
