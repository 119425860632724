import * as colors from '../colors';

export const ButtonStyle = {
    textStyle: {
      alignSelf: 'center',
      color: colors.SYC_WHITE,
      fontSize: 16,
      fontWeight: '600',
      paddingTop: 10,
      paddingBottom: 10
    },
    buttonStyle: {
      flex: 1,
      backgroundColor: colors.SYC_GREEN,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: colors.SYC_GREEN,
      elevation: 2,
      marginLeft: 5,
      marginRight: 5
    }
  };