import React, { Component } from 'react';
import { View } from 'react-native';
import { CardStyle as style } from '../../styles/common';

class Card extends Component {
    render() {
        return (
            <View style={this.props.style || style.containerStyle}>
                {this.props.children}
            </View>
        );
    }
}

export { Card };
