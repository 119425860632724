import * as colors from '../colors';

export const SelectorStyle = {
  pickerStyle: {
    margin: 15,
    padding: 3,
    color: colors.SYC_GREEN
  },
  itemStyle: {
    color: colors.SYC_GREEN
  }
}