export const formatNumber = (number) => {
  if (!isNumber(number)) {
    return '';
  }
  return (
    number.toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  );
};

export const isNumber = (value) => {
  if (value === undefined) {
    return false;
  }
  var numberValue = Number(value);
  return !isNaN(numberValue); 
};

export const absolute = (value) => {
  var abs;
  if (isNumber(value)) {
    abs = Math.abs(value);
  } else {
    abs = NaN;
  }
  return abs;
};
