import { CHANGE_RENDERING_MODE }from './types';
import { INITIAL_STATE } from './initialState';

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case CHANGE_RENDERING_MODE:
      return { ...state, renderingMode: action.payload };
    default:
      return state;
  }
}