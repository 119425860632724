import { INITIAL_STATE } from './initialState';
import { LOGIN_USER_SUCCESS } from '../../../login/redux/types';
import { MENU_LABEL_SELECTED } from '../../menu/redux/types';
import { ACTIONS_DOWNLOAD_PDF_REPORT, ACTIONS_USER_LOGOUT, ACTIONS_CHANGE_PASSWORD } from '../../../actions/redux/types';
import { WELCOME_MESSAGE, ACCOUNT_REPORT, PASSWORD_RECOVERY } from './constants';
import { HEADER_SHOW_MENU, HEADER_HIDE_MENU } from './types';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return { ...state, title: WELCOME_MESSAGE };
    case MENU_LABEL_SELECTED:
      return { ...state, title: action.payload };
    case ACTIONS_DOWNLOAD_PDF_REPORT:
      return { ...state, title: ACCOUNT_REPORT };
    case ACTIONS_USER_LOGOUT:
      return { ...state, title: INITIAL_STATE.title };
    case ACTIONS_CHANGE_PASSWORD:
      return { ...state, title: PASSWORD_RECOVERY };
    case HEADER_SHOW_MENU:
      return { ...state, showMenu: !state.showMenu };
    case HEADER_HIDE_MENU:
      return { ...state, showMenu: false };
    default:
      return state;
  }
};