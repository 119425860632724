import * as colors from '../../../styles/colors';
import { SelectorStyle } from '../../../styles/common';

export const TransactionsFormStyle = {
  containerStyle: {
    flex: 1,
    padding: 10
  },
  mobileContainerStyle: {
    flex: 1
  },
  noAccountMessageStyle: {
    marginTop: 25,
    marginStart: -200,
    color: colors.SYC_GRAY_CARD_TITLE,
    alignSelf: 'center'
  },
  tableRowStyle: {
    flex: 1,
  },
  instrumentDetailStyle: {
    flex: 3,
    padding: 5
  },
  instrumentAmountStyle: {
    flex: 2,
    padding: 5
  },
  currencyStyle: {
    marginEnd: 20,
    fontSize: 18,
    textAlign: 'left',
    color: colors.SYC_GRAY_CARD_TEXT
  },
  bigheaderTextLineItemStyle: {
    flex: 3,
    fontWeight: 'bold',
    justifyContent: 'space-around',
    marginEnd: '2.5rem'
  },
  headerTextLineItemStyle: {
    textAlign: 'center',
    flex: 1,
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: '2.5rem'
  },
  textLineItemStyle: {
    flex: 3,
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginEnd: '2.5rem'
  },
  centeredTextLineItemStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
    marginEnd: '2.5rem'
  },
  sectionHeaderTextStyle: {
    flex: 1,
    fontSize: 20,
    color: colors.SYC_GRAY_CARD_TEXT,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  selectorStyle: {
    maxWidth: '90vw',
    flex:2,
    minWidth:'max-content'
  },
  excelDowloadButtonStyle: {
    flex:1,
    padding: '1rem',
    alignItems: 'center',
    flexDirection: 'column',
    minWidth:'max-content'
  },
  filterStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent:'space-around',
    flexDirection: 'row',
    height:'100%',
    width: '100%'
  }
};