export const RowContainerStyle = {
  containerStyle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  mobileContainerStyle: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
