import * as colors from '../../../styles/colors';

export const ActionItemStyle = {
  containerNotSelectedStyle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 30,
    marginTop: 5,
    marginBottom: 5
  },
  containerStyle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 30,
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: colors.SYC_MENU_ITEM_SELECTED
  },
  selectorStyle: {
    width: 5,
    height: 30,
    marginEnd: 5,
    backgroundColor: colors.SYC_GREEN
  },
  noSelectorStyle: {
    marginStart: 10,
    backgroundColor: colors.SYC_WHITE
  },
  textStyle: {
    fontSize: 15,
    marginStart: 5
  }
};

export const UserItemStyle = {
  containerStyle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 5
  },
  textStyle: {
    fontSize: 15,
    marginStart: 5
  },
  imageStyle: {
    width: 23,
    height: 23
  },
};