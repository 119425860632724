import { INITIAL_STATE } from "./initialState";
import { 
  TRANSACTIONS_DATA_UPDATE,
  TRANSACTIONS_DATA_UPDATE_FAILURE,
  TRANSACTIONS_SWITCH_ACCOUNT,
  TRANSACTIONS_SWITCH_DATE,
  TRANSACTIONS_SWITCH_SPECIE
} from "./types";
import { TRANSACTIONS_START_DATE, TRANSACTIONS_END_DATE } from "./constants";

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRANSACTIONS_SWITCH_ACCOUNT:
      return { ...state, data: null, loading: true, selectedAccount: action.payload };
    case TRANSACTIONS_SWITCH_SPECIE:
      return { ...state, selectedSpecie: action.payload };
    case TRANSACTIONS_DATA_UPDATE:
      return { ...state, data: action.payload, loading: false };
    case TRANSACTIONS_DATA_UPDATE_FAILURE:
      return { ...state, data: null, loading: false };
    case TRANSACTIONS_SWITCH_DATE + TRANSACTIONS_START_DATE:
      return { ...state, startDate: action.payload, data: null, loading: true };
    case TRANSACTIONS_SWITCH_DATE + TRANSACTIONS_END_DATE:
      return { ...state, endDate: action.payload, data: null, loading: true };
    default:
      return state;
  }
};