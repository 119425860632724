import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { HighlightedTextStyle as style, STANDARD_FONT_SIZE } from '../../styles/common';
import { isNumber, formatNumber } from '../../utils/NumberUtils';

class HighlightedText extends Component {

  getTextStyle() {
    if (isNumber(this.props.value)) {
      if (this.props.value > 0) {
        return { ...style.positiveTextStyle, 
          fontSize: this.props.size || STANDARD_FONT_SIZE };
      } else {
        return { ...style.negativeTextStyle,
          fontSize: this.props.size || STANDARD_FONT_SIZE
        };
      }
    } else {
      return { ...style.standardTextStyle,
        fontSize: this.props.size || STANDARD_FONT_SIZE
      };
    }
  }

  render() {
    return (
      <View style={this.props.style}>
        <Text style={this.getTextStyle()}>
          {this.props.label}  {formatNumber(this.props.value)}
        </Text>
      </View>
    );
  }
}

export { HighlightedText };
