import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { 
  emailChanged,
  idChanged,
  isLoading,
  cancel,
  requestPasswordRecovery
} from './redux/actions';
import { 
  Card,
  CardSection, 
  Input, 
  Button, 
  ErrorText, 
  SuccessText,
  Spinner 
} from '../../components/common';
import { PasswordRecoveryFieldsStyle as style } from './style/PasswordRecoveryFields';
import { 
  APPLICATION_RENDERING_MODE_MOBILE as mobile,
  APPLICATION_RENDERING_MODE_SCREEN as screen,
  ENTER_KEY_CODE
} from '../common/application/redux/constants';
import { CardStyle } from '../../styles/common';

class PasswordRecoveryFields extends Component {
  constructor(props) {
    super(props);
    this.onRecoveryPress = this.onRecoveryPress.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyPress);
  }

  handleKeyPress(event) {
    if (event.keyCode === ENTER_KEY_CODE) {
      this.onRecoveryPress();
    }
  }

  onEmailChange(email) {
    this.props.emailChanged(email);
  }

  onIdChange(id) {
    this.props.idChanged(id);
  }

  onRecoveryPress() {
    this.props.isLoading(true);
    this.props.requestPasswordRecovery(this.props.email, this.props.id);
  }

  onCancelPress() {
    this.props.cancel();
  }

  renderLoading() {
    if (this.props.loading) {
      return (
        <CardSection>
          <Spinner />
        </CardSection>
      );
    }
  }

  renderMessage() {
    if (this.props.error) {
      return (
        <ErrorText>{this.props.error}</ErrorText>
      );
    } else if (this.props.successMessage) {
      return (
        <SuccessText>{this.props.successMessage}</SuccessText>
      );
    }
  }

  getCancelButton() {
    if (this.props.noCancel) {
      return <View/>;
    } else {
      return(
        <Button
          textStyle={style.cancelTextStyle}
          style={style.cancelButtonStyle}
          onPress={this.onCancelPress.bind(this)}
        >
          CANCELAR
        </Button>
      );
    }
  }

  getCardStyle() {
    if (this.props.renderingMode === mobile) {
      return CardStyle.mobileContainerStyle;
    } else if (this.props.renderingMode === screen) {
      return CardStyle.containerStyle;
    }
  }

  render () {
    return (
        <Card
          style={this.getCardStyle()}
        >
          <CardSection>
            <Input
              placeholder="Correo electrónico"
              labelImage={require('./assets/Usuario.svg')}
              onChangeText={this.onEmailChange.bind(this)}
              value={this.props.email}
            />
          </CardSection>
          <CardSection>
            <Input
              placeholder="DNI"
              labelImage={require('./assets/Login-Candado.svg')}
              onChangeText={this.onIdChange.bind(this)}
              value={this.props.id}
            />
          </CardSection>

          {this.renderLoading()}

          {this.renderMessage()}

          <CardSection>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Button 
                onPress={this.onRecoveryPress.bind(this)}
              >
                RECUPERAR CONTRASEÑA
              </Button>
              {this.getCancelButton()}
            </View>
          </CardSection>
        </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    email: state.recovery.email,
    id: state.recovery.id,
    loading: state.recovery.loading,
    error: state.recovery.error,
    successMessage: state.recovery.successMessage,
    renderingMode: state.application.renderingMode
  };
};

export default connect(mapStateToProps, {emailChanged, idChanged, isLoading, cancel, requestPasswordRecovery})(PasswordRecoveryFields);