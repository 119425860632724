import * as colors from '../../../styles/colors';

export const PdfReportFormStyle = {
  containerStyle: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 10
  },
  formStyle: {
    alignItems: 'flex-start'
  },
  headingStyle: {
    flexDirection: 'row', 
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  headingTextStyle: {
    margin: 15,
    fontWeight: 'bold',
    color: colors.SYC_GRAY_CARD_TITLE
  },
  enabledButtonStyle: {
    flex: 1,
    backgroundColor: colors.SYC_GREEN,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: colors.SYC_GREEN,
    paddingStart: 10,
    paddingEnd: 10,
    paddingTop: 5,
    paddingBottom: 5,
    elevation: 2,
    marginLeft: 5,
    marginRight: 5,
    maxHeight: "max-content"
  },
  disabledButtonStyle: {
    flex: 1,
    backgroundColor: colors.SYC_GREEN,
    opacity: 0.3,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: colors.SYC_GREEN,
    paddingStart: 10,
    paddingEnd: 10,
    paddingTop: 5,
    paddingBottom: 5,
    elevation: 2,
    marginLeft: 5,
    marginRight: 5,
    maxHeight: "max-content"
  }
};
