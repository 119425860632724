import React, { Component } from 'react';
import { View, Switch, Text, Picker } from 'react-native';
import { connect } from 'react-redux';
import { PdfReportFormStyle as style } from './style/PdfReportForm';
import { DateSelector, Selector, Separator, Button, Spinner, ErrorText, AdaptiveCard, SimpleModal } from '../../components/common';
import {
  ARS,
  USD,
  USDB,
  REPORT_PORTFOLIO,
  REPORT_PERFORMANCE,
  PORTFOLIO_AGREEMENT_DATE,
  PERFORMANCE_DATE_FROM,
  PERFORMANCE_DATE_TO,
  PORTFOLIO_CURRENCY,
  PERFORMANCE_CURRENCY,
  TRANSACTIONS_DATE_FROM,
  TRANSACTIONS_DATE_TO,
  REPORT_TRANSACTIONS
} from './redux/constants';
import {
  toggleReport,
  changeReportDate,
  changeReportCurrency,
  downloadPdf,
  closeNoDataModal
} from './redux/actions';
import { yesterday } from '../../utils/DateUtils';
class PdfReportForm extends Component {

  toggleReport(report) {
    this.props.toggleReport(report);
  };

  onChangeDate(date, which) {
    this.props.changeReportDate(date, which);
  };

  onCloseModal() {
    this.props.closeNoDataModal();
  };

  onCurrencyChange(currency, which) {
    this.props.changeReportCurrency(currency, which);
  }

  onDownload() {
    this.props.downloadPdf(
      this.props.account.accountId,
      this.props.usePortfolio,
      this.props.portfolioAgreementDate,
      this.props.portfolioCurrency,
      this.props.usePerformance,
      this.props.performanceDateFrom,
      this.props.performanceDateTo,
      this.props.performanceCurrency,
      this.props.useTransactions,
      this.props.transactionsDateFrom,
      this.props.transactionsDateTo
    );
  }

  getButtonStyle() {
    if (this.props.loading) {
      return style.disabledButtonStyle;
    } else {
      return style.enabledButtonStyle;
    }
  }

  getLoadingView() {
    if (this.props.loading) {
      return (
        <View style={{ padding: 15 }}>
          <Spinner />
          <Text>Preparando informe...</Text>
        </View>
      );
    } else {
      return (
        <View />
      );
    }
  }
  getNoDataModal() {
    if (this.props.showModal) {
      return (
        <SimpleModal
          showModal={this.props.showModal}
          text={this.props.modalMessage}
          buttonText={'Aceptar'}
          onClose={() => this.onCloseModal()}>
        </SimpleModal>
      );
    } else {
      return (
        <View />
      );
    }
  }

  getErrorMessage() {
    if (this.props.error) {
      return (
        <ErrorText>{this.props.error}</ErrorText>
      );
    }
  }

  render() {
    return (
      <View style={style.containerStyle}>
        <AdaptiveCard
          title="Personalice su informe"
          size="medium"
          renderingMode={this.props.renderingMode}
        >
          <View style={style.headingStyle}>
            <Switch
              value={this.props.usePortfolio}
              onValueChange={(value) => this.toggleReport(REPORT_PORTFOLIO)}
            />
            <Text style={style.headingTextStyle}>Portafolio</Text>
          </View>
          <DateSelector
            label="Fecha de Concertación"
            startDate={this.props.portfolioAgreementDate}
            onChange={(date) => this.onChangeDate(date, PORTFOLIO_AGREEMENT_DATE)}
          />
          <Selector
            selectedValue={this.props.portfolioCurrency}
            onValueChange={(currency) => this.onCurrencyChange(currency, PORTFOLIO_CURRENCY)}
          >
            <Picker.Item label={ARS} value={ARS} />
            <Picker.Item label={USD} value={USD} />
          </Selector>

          {/*
          <Separator />

          <View style={style.headingStyle}>
            <Switch
              value={this.props.usePerformance}
              onValueChange={(value) => this.toggleReport(REPORT_PERFORMANCE)}
            />
            <Text style={style.headingTextStyle}>Rendimiento</Text>
          </View>
          <DateSelector
            rangePick
            labelStart="Fecha desde"
            labelEnd="Fecha hasta"
            startDate={this.props.performanceDateFrom}
            endDate={this.props.performanceDateTo}
            maxDate={yesterday()}
            onChangeStart={(date) => this.onChangeDate(date, PERFORMANCE_DATE_FROM)}
            onChangeEnd={(date) => this.onChangeDate(date, PERFORMANCE_DATE_TO)}
          />
          <Selector
            selectedValue={this.props.performanceCurrency}
            onValueChange={(currency) => this.onCurrencyChange(currency, PERFORMANCE_CURRENCY)}
          >
            <Picker.Item label={ARS} value={ARS} />
            <Picker.Item label={USDB} value={USDB} />
          </Selector>

          */}

          <Separator />

          <View style={style.headingStyle}>
            <Switch
              value={this.props.useTransactions}
              onValueChange={(value) => this.toggleReport(REPORT_TRANSACTIONS)}
            />
            <Text style={style.headingTextStyle}>Movimientos</Text>
          </View>
          <DateSelector
            rangePick
            labelStart="Fecha desde"
            labelEnd="Fecha hasta"
            startDate={this.props.transactionsDateFrom}
            endDate={this.props.transactionsDateTo}
            maxDate={yesterday()}
            onChangeStart={(date) => this.onChangeDate(date, TRANSACTIONS_DATE_FROM)}
            onChangeEnd={(date) => this.onChangeDate(date, TRANSACTIONS_DATE_TO)}
          />
          {this.getErrorMessage()}
        </AdaptiveCard>
        {this.getLoadingView()}
        {this.getNoDataModal()}
        <Button
          style={this.getButtonStyle()}
          disabled={this.props.loading}
          onPress={this.onDownload.bind(this)}
        >
          Descargar informe
        </Button>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.account.selectedAccount,
    renderingMode: state.application.renderingMode,
    loading: state.pdf.loading,
    usePortfolio: state.pdf.usePortfolio,
    usePerformance: state.pdf.usePerformance,
    useTransactions: state.pdf.useTransactions,
    portfolioAgreementDate: state.pdf.portfolioAgreementDate,
    performanceDateFrom: state.pdf.performanceDateFrom,
    performanceDateTo: state.pdf.performanceDateTo,
    transactionsDateFrom: state.pdf.transactionsDateFrom,
    transactionsDateTo: state.pdf.transactionsDateTo,
    portfolioCurrency: state.pdf.portfolioCurrency,
    performanceCurrency: state.pdf.performanceCurrency,
    error: state.pdf.error,
    showModal: state.pdf.showModal,
    modalMessage: state.pdf.modalMessage,
  };
};

export default connect(mapStateToProps, { toggleReport, changeReportDate, changeReportCurrency, downloadPdf, closeNoDataModal })(PdfReportForm);
