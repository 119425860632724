import * as colors from '../../../styles/colors';

export const PerformanceFormStyle = {
  containerStyle: {
    flex: 1,
    padding: 10
  },
  mobileContainerStyle: {
    flex: 1,
  },
  headingEndStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginEnd: 10
  },
  headerTextLineItemStyle: {
    textAlign: 'center',
    flex: 1,
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  textLineItemStyle: {
    flex: 1,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableStyle: {
    flexGrow: 1,
  },
  bottomTableItemStyle: {
    flex: 1,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomTableRowStyle: {
    marginTop: '1rem',
    paddingTop: '0.3rem',
    borderTopColor: colors.SYC_LIGHT_GRAY,
    borderTopWidth: 2,
  },
  bottomTableTitleStyle: {
    flex: 3,
    fontWeight: 'bold',
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableListStyle: {
    flex: 1
  },
  resultCard: {
    containerStyle: {
      padding: 5
    },
    textCardStyle: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 20
    }
  },
  chartStyle: {
    width: '90%',
    height: 350,
    alignSelf: 'center',
    resizeMode: 'contain'
  },
  mobileChartStyle: {
    width: '90vw',
    height: 350,
    alignSelf: 'center',
    resizeMode: 'contain'
  },
  noAccountMessageStyle: {
    marginTop: 25,
    marginStart: -200,
    color: colors.SYC_GRAY_CARD_TITLE,
    alignSelf: 'center'
  },
  mobileTableText: {
    minWidth:'8rem'
  },
  mobileBottomTitleText: {
    minWidth: 'calc(8rem * 3)'
  },
  normalTableText: {
    minWidth:'6rem'
  },
  normalBottomTitleText: {
    minWidth: 'calc(6rem * 3)'
  },
  
};
