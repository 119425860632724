export const ModalStyle = {
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: 15,
  },
  modalText: {
    marginBottom: 25
  },
  modalContent: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
    minWidth: '45%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
};
