import React, { Component } from 'react';
import { Text, View, Image, TouchableWithoutFeedback } from 'react-native';
import { connect } from 'react-redux';
import { HeaderStyle as style } from './style/Header';
import {
    APPLICATION_RENDERING_MODE_MOBILE as mobile,
    APPLICATION_RENDERING_MODE_SCREEN as screen
} from '../application/redux/constants';
import {
    showMenu
} from './redux/actions';

class Header extends Component {

    onShowMenu() {
        this.props.showMenu();
    }

    getTextStyle() {
        if (this.props.renderingMode === mobile) {
            return style.mobileTextStyle;
        } else if (this.props.renderingMode === screen) {
            return style.textStyle;
        }
    }

    getHeaderLogo() {
        if (this.props.renderingMode === mobile) {
            if (this.props.token) {
                return (
                    <TouchableWithoutFeedback
                        onPress={this.onShowMenu.bind(this)}
                    >
                        <Image
                            source={require('./assets/hamburger.svg')}
                            style={style.mobileImageStyle}
                        />
                    </TouchableWithoutFeedback>
                );
            } else {
                return (
                    <Image
                        source={require('./assets/logo_favicon.png')}
                        style={style.mobileImageStyle}
                    />
                );
            }
        } else {
            return (
                <Image
                    source={require('./assets/full_logo.png')}
                    style={style.imageStyle}
                />
            );
        }
    }

    render() {
        return (
            <View style={style.viewStyle}>
                <View style={style.headerLogoStyle}>{this.getHeaderLogo()}</View>
                <View style={style.titleStyle}>
                    <Text style={this.getTextStyle()}>{this.props.title}</Text>
                </View>
                <View style={style.actionsStyle} />
            </View>
        );
    }
};

const mapStateToProps = state => {
    return {
        title: state.header.title,
        token: state.login.token,
        renderingMode: state.application.renderingMode
    };
};

export default connect(mapStateToProps, { showMenu })(Header);
