import * as colors from '../colors';

export const CardSectionStyle = {
    containerStyle: {
      borderBottomWidth: 1,
      padding: 30,
      backgroundColor: colors.SYC_WHITE,
      justifyContent: 'flex-start',
      flexDirection: 'row',
      borderColor: '#DDD',
      position: 'relative'
    }
  }