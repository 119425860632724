import { 
  ACTIONS_SHOW_USER_PANE, 
  ACTIONS_USER_LOGOUT,
  ACTIONS_DOWNLOAD_PDF_REPORT,
  ACTIONS_SHOW_ACCOUNTS_PANE,
  ACTIONS_CHANGE_PASSWORD
} from './types';
import { INITIAL_STATE } from './initialState';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS_SHOW_USER_PANE:
      return { 
        ...state, 
        showUserPane: !state.showUserPane, 
      }
    case ACTIONS_DOWNLOAD_PDF_REPORT:
    case ACTIONS_SHOW_ACCOUNTS_PANE:
    case ACTIONS_USER_LOGOUT: 
    case ACTIONS_CHANGE_PASSWORD: 
      return { ...state, showUserPane: false }
    default:
      return state;
  }
} 