import {
  LOGIN_EMAIL_PARAM,
  LOGIN_PASSWORD_PARAM
} from './constants';
import {
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  IS_LOADING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_UNAUTHORIZED,
  LOGIN_USER_FAILED,
  LOGIN_EMPTY_FIELDS,
  LOAD_FAILED,
  LOGIN_RECOVER_PASSWORD,
  LOADING_TOKEN
} from './types';
import {
  LOGIN_URL,
  KEEP_ALIVE_URL
} from '../../../service/Endpoints';
import { store, retrieve, remove } from '../../../storage/Storage';
import { TOKEN } from '../../../storage/keys';
import { fetchAccounts } from '../../actions/redux/actions';

export const emailChanged = (text) => {
  return {
    type: EMAIL_CHANGED,
    payload: text
  };
};

export const passwordChanged = (text) => {
  return {
    type: PASSWORD_CHANGED,
    payload: text
  };
};

export const isLoading = (status) => {
  return {
    type: IS_LOADING,
    payload: status
  };
};

export const recoverPassword = () => {
  return {
    type: LOGIN_RECOVER_PASSWORD
  };
};

export const loginUser = ({ email, password }) => {
  return (dispatch) => {
    const fieldsHaveValues = email !== "" && password !== "";
    if (fieldsHaveValues) {
      fetch(LOGIN_URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody(email, password)
      })
        .then(response => dispatchResponse(dispatch, response))
        .catch(() => dispatchError(dispatch))
    } else {
      dispatchLoginEmptyFields(dispatch);
    }
  };
};

export const loadingToken = (status) => {
  return { 
    type: LOADING_TOKEN, 
    payload: status 
  };
};

export const loadToken = () => {
  return (dispatch) => {
    retrieve(TOKEN)
      .then(token => fetch(KEEP_ALIVE_URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': token
        }
      }))
      .then(response => dispatchLoad(dispatch, response))
      .catch(() => {
        dispatchError(dispatch);
        dispatchLoadingTokenFinish(dispatch);
      });
  }
};

const dispatchLoad = (dispatch, response) => {
  if (response.status === 200) {
    response.json()
      .then(data => onLoadSuccess(dispatch, data));
  } else {
    onLoadFailed(dispatch);
    dispatchLoadingTokenFinish(dispatch);
  }
};

const onLoadSuccess = (dispatch, data) => {
  onLoginSuccess(dispatch, data);
  dispatchLoadingTokenFinish(dispatch);
};

const onLoadFailed = (dispatch) => {
  remove(TOKEN);

  dispatch({
    type: LOAD_FAILED
  });
};

const dispatchResponse = (dispatch, response) => {
  if (response.status === 200) {
    response.json()
      .then(data => onLoginSuccess(dispatch, data));
  } else if (response.status === 401) {
    onLoginFailed(dispatch);
  }
};

const dispatchError = (dispatch) => {
  dispatch({
    type: LOGIN_USER_FAILED
  });
};

const dispatchLoadingTokenFinish = (dispatch) => {
  dispatch(loadingToken(false));
};

const dispatchLoginEmptyFields = (dispatch) => {
  dispatch({
    type: LOGIN_EMPTY_FIELDS
  });
};

const onLoginSuccess = (dispatch, data) => {
  store(TOKEN, data.token);

  fetchAccounts(dispatch, data.token);

  dispatch({
    type: LOGIN_USER_SUCCESS,
    payload: data.token
  });
};

const onLoginFailed = (dispatch) => {
  remove(TOKEN);

  dispatch({
    type: LOGIN_USER_UNAUTHORIZED
  });
};

const formBody = (email, password) => {
  return `${LOGIN_EMAIL_PARAM}=${encodeURIComponent(email)}&${LOGIN_PASSWORD_PARAM}=${encodeURIComponent(password)}`;
}