export const LoadingIndicatorStyle = {
  containerStyle: {
    flex: 1,
    padding: 15,
    paddingTop: 30,
    alignSelf: 'stretch'
  },
  indicatorStyle: {
    alignSelf: 'center',
    justifyContent: 'center'
  },
  loadingTextStyle: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: 20
  }
}