import React, { Component } from 'react';
import {
    FlatList,
    View,
    Platform,
    UIManager,
    LayoutAnimation
} from 'react-native';
import { connect } from 'react-redux';
import MenuItem from './MenuItem';
import { MenuStyle as style } from './style/Menu';
import {
    APPLICATION_RENDERING_MODE_MOBILE as mobile,
    APPLICATION_RENDERING_MODE_SCREEN as screen
} from '../application/redux/constants';

class Menu extends Component {

    componentWillMount() {
        if (Platform.OS === 'android') {
            UIManager.setLayoutAnimationEnabledExperimental && UIManager.setLayoutAnimationEnabledExperimental(true);
        }
        LayoutAnimation.easeInEaseOut();
    }

    componentWillUpdate() {
        LayoutAnimation.easeInEaseOut();
    }

    renderItem(menuItem) {
        return <MenuItem item={menuItem.item} />;
    }

    getMenu() {
        if (this.props.renderingMode === mobile) {
            if (this.props.show) {
                return this.renderMenu();
            } else {
                return <View />
            }
        } else if (this.props.renderingMode === screen) {
            return this.renderMenu();
        }
    }
    getContainerStyle() {
        if (this.props.renderingMode === mobile) {
            return ([style.mobileContainerStyle, style.containerStyle])
        } else {
            return (style.containerStyle);
        }
    }

    renderMenu() {
        return (
            <View style={this.getContainerStyle()}>
                <FlatList
                    data={this.filter()}
                    renderItem={this.renderItem}
                    keyExtractor={(menuItem) => menuItem.id.toString()}
                />
            </View>
        );
    }

    filter() {
        console.log(this.props.data);
        //return (this.props.menuItems.filter(item => item.label !== "Rendimiento" && (this.props.renderingMode !== mobile || (item.label !== "Transferencias" && item.label !== "Comprobantes"))));
        return (this.props.menuItems.filter(item => item.label !== "Rendimiento" && item.label !== "Transferencias" && item.label !== "Comprobantes"));
    }

    render() {
        return (
            this.getMenu()
        );
    }
}

const mapStateToProps = state => {
    return {
        menuItems: state.menuItems,
        renderingMode: state.application.renderingMode,
        show: state.header.showMenu
    };
};

export default connect(mapStateToProps)(Menu);
