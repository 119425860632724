import {
  MENU_ID_SELECTED,
  MENU_LABEL_SELECTED
} from './types';
import { HEADER_HIDE_MENU } from '../../header/redux/types';

const selectMenuId = (dispatch, menuId) => {
  dispatch({
    type: MENU_ID_SELECTED,
    payload: menuId
  });
};

const selectMenuLabel = (dispatch, menuLabel) => {
  dispatch({
    type: MENU_LABEL_SELECTED,
    payload: menuLabel
  });
};

const hideMenu = (dispatch) => {
  dispatch({
    type: HEADER_HIDE_MENU
  });
};

export const selectMenu = (menuId, menuLabel) => {
  return (dispatch) => {
    hideMenu(dispatch);
    selectMenuId(dispatch, menuId);
    selectMenuLabel(dispatch, menuLabel);
  }
};