import React, { Component } from 'react';
import { View } from 'react-native';
import { PasswordRecoveryFormStyle as style } from './style/PasswordRecoveryForm';
import PasswordRecoveryFields from './PasswordRecoveryFields';

class PasswordRecoveryForm extends Component {
  render() {
    return (
      <View
        style={style.containerStyle}
      >
        <PasswordRecoveryFields
          noCancel
        />
      </View>
    );
  }
}

export default PasswordRecoveryForm;
