import { 
  ACTIONS_SHOW_USER_PANE,
  ACTIONS_USER,
  ACTIONS_DOWNLOAD_PDF_REPORT,
  ACTIONS_SHOW_ACCOUNTS_PANE,
  ACTIONS_SELECT_ACCOUNT,
  ACTIONS_ACCOUNTS_FETCH,
  ACTIONS_USER_LOGOUT
} from './types';
import {
  ACCOUNT_FETCH_URL, LOGOUT_URL
} from '../../../service/Endpoints';
import { remove, retrieve } from '../../../storage/Storage';
import { TOKEN } from '../../../storage/keys';
import { SESSION_EXPIRED } from '../../login/redux/types';

export const showUserActionPane = () => {
  return {
    type: ACTIONS_SHOW_USER_PANE
  }
};

export const showPdfPage = () => {
  return {
    type: ACTIONS_DOWNLOAD_PDF_REPORT
  }
};

export const selectUserAction = (id) => {
  
  if (ACTIONS_USER[id] === ACTIONS_USER_LOGOUT) {
    retrieve(TOKEN)
    .then(token => fetch(LOGOUT_URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': token
      }
    }))
    .then(remove(TOKEN));
  }

  return {
    type: ACTIONS_USER[id],
  };

};

export const showAccounts = () => {
  return {
    type: ACTIONS_SHOW_ACCOUNTS_PANE
  }
};

export const selectAccount = (account) => {
  return {
    type: ACTIONS_SELECT_ACCOUNT,
    payload: account
  }
};

export const fetchAccounts = (dispatch, token) => {
  fetch(ACCOUNT_FETCH_URL, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': token
    }
  }).then(data => data.json())
  .then(data => {
    dispatch({ 
      type: ACTIONS_ACCOUNTS_FETCH, 
      payload: data
    })
    dispatch({
      type: ACTIONS_SELECT_ACCOUNT,
      payload: data[0]
    });
  });
};

export const dispatchLogoutProcess = dispatch => {
  retrieve(TOKEN)
  .then(token => fetch(LOGOUT_URL, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': token
    }
  }))
  .then(() => {
    remove(TOKEN);
    dispatch({ type: SESSION_EXPIRED});
  });
}
