import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { AccountActionsStyle as style } from './style/AccountActions';
import UserActionPane from './UserActionPane';
import PdfReportActionPane from './PdfReportActionPane';
import AccountSelectorPane from './AccountSelectorPane';
import {
  APPLICATION_RENDERING_MODE_MOBILE as mobile,
  APPLICATION_RENDERING_MODE_SCREEN as screen
} from '../common/application/redux/constants';

class AccountActions extends Component {

  renderActions() {
    if (this.props.token) {
      if(this.props.renderingMode !== mobile)
      {
        return (
          <View style={style.containerStyle}>
            <AccountSelectorPane />
            <PdfReportActionPane />
            <UserActionPane/>
          </View>
        );
      }
      else {
        return (
          <View style={style.containerStyle}>
            <AccountSelectorPane />
            <UserActionPane/>
          </View>
        );
      }
    } else {
      return <View/>
    }
  }

  render() {
    return (
      this.renderActions()
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.login.token,
    renderingMode: state.application.renderingMode
  }
};

export default connect(mapStateToProps)(AccountActions);
