import * as colors from '../colors';

export const EmptyAdaptiveCardStyle = {
  containerStyle: {
    backgroundColor: colors.SYC_WHITE,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 5,
    margin: 10,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.SYC_LIGHT_GRAY,
    borderBottomWidth: 0,
    shadowColor: colors.BLACK,
    shadowRadius: 5,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.2,
    zIndex: 2
  },
  mobileContainerStyle: {
    width: 'calc(100vw - 20px)',
    backgroundColor: colors.SYC_WHITE,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.SYC_LIGHT_GRAY,
    borderBottomWidth: 0,
    shadowColor: colors.BLACK,
    shadowRadius: 5,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.2,
    zIndex: 2
  }
};
