import { INITIAL_STATE } from './initalState';
import {
  LOGIN_FAILED_MESSAGE,
  LOGIN_UNAUTHORIZED_MESSAGE,
  LOGIN_PASSWORD_RECOVERY_FIELDS,
  LOGIN_LOGIN_FIELDS,
  LOGIN_EMPTY_FIELDS_MESSAGE,
  SESSION_EXPIRED_MESSAGE
} from './constants';
import {
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_UNAUTHORIZED,
  LOGIN_EMPTY_FIELDS,
  LOGIN_USER_FAILED,
  IS_LOADING,
  LOAD_FAILED,
  LOGIN_RECOVER_PASSWORD,
  LOADING_TOKEN,
  SESSION_EXPIRED
} from './types';
import { ACTIONS_USER_LOGOUT } from '../../actions/redux/types';
import { PASSWORD_RECOVERY_CANCEL, PASSWORD_RECOVERY_SUCCESS } from '../../password_recovery/redux/types';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EMAIL_CHANGED:
      return { ...state, email: action.payload };
    case PASSWORD_CHANGED:
      return { ...state, password: action.payload };
    case IS_LOADING:
      return { ...state, loading: action.payload, error: '' };
    case LOGIN_USER_SUCCESS:
      return { ...state, token: action.payload, password: '', loading: false };
    case LOGIN_USER_UNAUTHORIZED:
      return { ...state, error: LOGIN_UNAUTHORIZED_MESSAGE, password: '', loading: false, token: ''}
    case SESSION_EXPIRED:
      return { ...state, ...INITIAL_STATE, error: SESSION_EXPIRED_MESSAGE}
    case LOGIN_USER_FAILED:
      return { ...state, error: LOGIN_FAILED_MESSAGE, password: '', loading: false };
    case LOGIN_EMPTY_FIELDS:
      return { ...state, error: LOGIN_EMPTY_FIELDS_MESSAGE, loading: false };
    case LOGIN_RECOVER_PASSWORD:
      return { ...state, fields: LOGIN_PASSWORD_RECOVERY_FIELDS };
    case PASSWORD_RECOVERY_CANCEL:
      return { ...state, fields: LOGIN_LOGIN_FIELDS };
    case PASSWORD_RECOVERY_SUCCESS:
    case ACTIONS_USER_LOGOUT:
    case LOAD_FAILED:
      return { ...state, token: '', password: '', loading: false };
    case LOADING_TOKEN:
      return { ...state, loadingToken: action.payload };
    default:
      return state;
  }
};
