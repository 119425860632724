import React, { Component } from 'react';
import { View, Image, TouchableWithoutFeedback } from 'react-native';
import { connect } from 'react-redux';
import { showPdfPage } from './redux/actions';
import { ActionStyle as style } from './style/Action';

class UserActionPane extends Component {

  onShowAction() {
    this.props.showPdfPage();
  }

  render() {
    return (
      <View>
        <TouchableWithoutFeedback
          onPress={this.onShowAction.bind(this)}
        >
          <Image
            source={require('./assets/Impresora_tool.svg')}
            style={style.imageStyle}
          />
        </TouchableWithoutFeedback>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {}
};

export default connect(mapStateToProps, { showPdfPage })(UserActionPane);
