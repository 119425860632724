import React, { Component } from 'react';
import { 
    View, 
    Image, 
    Text,
    TouchableOpacity
} from 'react-native';
import { connect } from 'react-redux';
import { MenuItemStyle as styles} from './style/MenuItem';
import { selectMenu } from './redux/actions';

class MenuItem extends Component {

    isSelectedView() {
        if (this.props.selected === this.props.item.id) {
            return (
                styles.containerStyle
            );
        } else {
            return (
                styles.containerNoSelectedStyle
            );
        }
    }

    shouldShowSelector() {
        if (this.props.selected === this.props.item.id) {
            return (
                styles.selectorStyle
            );
        } else {
            return (
                styles.noSelectorStyle
            );
        }
    }

    render() {
        const { id, label, icon } = this.props.item;

        return (
            <TouchableOpacity
                onPress={() => this.props.selectMenu(id, label)}
            >
                <View style={this.isSelectedView()}>
                    <Image
                        source={require('./assets/selector_color.png')} 
                        style={this.shouldShowSelector()}
                    />
                    <Image
                        source={require('./assets/' + icon)}
                        style={styles.imageStyle}
                    />
                    <Text style={styles.textStyle}>{label}</Text>
                </View>
            </TouchableOpacity>
        );
    }
}

const mapStateToProps = state => {
    return { 
        selected: state.menu.selectedMenu
    };
};

export default connect(mapStateToProps, {selectMenu})(MenuItem);
