import * as colors from '../colors';

export const STANDARD_FONT_SIZE = 28;
export const HighlightedTextStyle = {
  standardTextStyle: {
    fontWeight: '500',
    color: colors.SYC_GRAY_CARD_TEXT
  },
  positiveTextStyle: {
    fontWeight: '500',
    color: colors.SYC_GREEN
  },
  negativeTextStyle: {
    fontWeight: '500',
    color: colors.SYC_RED
  }
};
