export const AppStyle = {
    mainContainerStyle: {
        flex:1,
        flexDirection: 'row',
        justifyContent: 'flex-start' ,
    },
    applicationBackground:{
        backgroundColor: '#ebebeb',
        backgroundSize:'cover',
        minHeight: '100vh'
    }
};
