import { 
  ACTIONS_SHOW_ACCOUNTS_PANE, 
  ACTIONS_SELECT_ACCOUNT, 
  ACTIONS_SHOW_USER_PANE,
  ACTIONS_DOWNLOAD_PDF_REPORT
} from './types';
import { INITIAL_STATE } from './initialState';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS_SELECT_ACCOUNT:
      return { 
        ...state, 
        selectedAccount: action.payload, 
        showAccounts: false 
      }
    case ACTIONS_SHOW_USER_PANE:
    case ACTIONS_DOWNLOAD_PDF_REPORT:
      return {
        ...state,
        showAccounts: false
      }
    case ACTIONS_SHOW_ACCOUNTS_PANE:
      return {
        ...state,
        showAccounts: !state.showAccounts,
      }
    default:
      return state;
  }
}