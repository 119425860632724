import React, { Component } from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity
} from 'react-native';
import { connect } from 'react-redux';
import { UserItemStyle as style } from './style/ActionItem';
import { selectUserAction } from './redux/actions';

class UserActionItem extends Component {
  render() {
    const { id, label, icon } = this.props.item;

    return(
      <TouchableOpacity
        onPress={() => this.props.selectUserAction(id)}
      >
        <View style={style.containerStyle}>
          <Image
            source={require('./assets/' + icon)}
            style={style.imageStyle}
          />
          <Text style={style.textStyle} numberOfLines={1}>{label}</Text>
        </View>
      </TouchableOpacity>
    );
  }
}

export default connect(null, { selectUserAction })(UserActionItem);
