import * as colors from '../../../styles/colors';

export const LoginFormStyle = {
  containerStyle: {
    height: 'calc(100vh - 50px)',
    padding: '10vh',
    flex: 1
  },
  imageStyle: {
    resizeMode: 'cover',
    flex: 1
  },
  formStyle: {
    // opacity: 0.95,
    flex: 1
  },
  passwordRecoveryButtonStyle: {
    backgroundColor: 'transparent', 
    marginTop: 25, 
    alignSelf: 'center'
  },
  passwordRecoveryTextStyle: {
    color: colors.SYC_GRAY_CARD_TITLE
  }
};