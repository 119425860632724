import React, { Component } from 'react';
import { View, Text, FlatList, Picker, Image } from 'react-native';
import { connect } from 'react-redux';
import {
  RowContainer,
  AdaptiveCard,
  Selector,
  HighlightedText,
  SmallText,
  TextLine,
  Column,
  DateSelector,
  EmptyAdaptiveCard,
  CenteredErrorMessage
} from '../../components/common';
import {
  switchCurrency,
  switchChartType,
  updateData,
  noAccountSelected,
  invalidateAccount,
  changeSelectedDate
} from './redux/actions';
import { formatNumber } from '../../utils/NumberUtils';
import { PortfolioFormStyle as style } from './style/PortfolioForm';
import { CHART_TYPE_ACTIVE, CHART_TYPE_CURRENCY, ARS, USD, } from './redux/constants';
import { LoadingIndicator } from '../../components/common/LoadingIndicator';
import { APPLICATION_RENDERING_MODE_SCREEN, APPLICATION_RENDERING_MODE_MOBILE } from '../common/application/redux/constants';
import { LARGE } from '../../components/common/constants';
import { format } from '../../utils/DateUtils';

class PortfolioForm extends Component {


  invalidateSelectedAccount() {
    if (this.props.account !== this.props.selectedAccount) {
      this.props.invalidateAccount(this.props.account);
    }
  }

  loadData() {
    const { account, selectedDate, data } = this.props;
    this.invalidateSelectedAccount();

    if (account.accountId !== undefined) {
      if (data === null) {
        this.props.updateData(account, selectedDate);
      }
    } else {
      this.props.noAccountSelected();
    }
  }

  onCurrencyChange(value, index) {
    this.props.switchCurrency(value);
  }

  onChartTypeChange(value, index) {
    this.props.switchChartType(value);
  }

  getTotal(value) {
    if (this.props.currency === ARS) {
      return value.sum;
    } else if (this.props.currency === USD) {
      return value.sumUsd;
    }
  }
  renderMobileTable(data) {
    return (
      <View>
        <FlatList
          style={{ flex: 1 }}
          data={data}
          renderItem={(item) => this.renderMobileItem(item)}
        />
      </View>
    );
  }
  renderTable(data) {
    return (
      <View>
        <TextLine>
          <Text style={style.headerTextLineItemStyleL}>
            Concepto
          </Text>
          <Text style={style.headerTextLineItemStyle}>
            Estado
          </Text>
          <Text style={style.headerTextLineItemStyleR}>
            Cantidad
          </Text>
          <Text style={style.headerTextLineItemStyleR}>
            Precio
          </Text>
          <Text style={style.headerTextLineItemStyleR}>
            Valor de Mercado
          </Text>
          <Text style={style.headerTextLineItemStyleR}>
            Valor de Mercado {this.props.currency}
          </Text>
          <Text> </Text>          
        </TextLine>
        <FlatList
          style={{ flex: 1 }}
          data={data}
          renderItem={(item) => this.renderItem(item)}
          keyExtractor={(item) => item.name}
        />
      </View>
    );
  }
  renderMobileItem(menuItem) {
    return (
      <View style={style.mobileCardItem}>
        <View style={style.rowMobileCard}>
          <Text style={style.headMobileCardItem}>
            {menuItem.item.name}
          </Text>
        </View>
        <View style={style.rowMobileCard}>
          <Text style={style.nameMobileCardItem}>
            Estado
          </Text>
          <Text style={style.valueMobileCardItem}>
            {menuItem.item.positionDTO.estado}
          </Text>
        </View>
        <View style={style.rowMobileCard}>
          <Text style={style.nameMobileCardItem}>
            Cantidad
          </Text>
          <Text style={style.valueMobileCardItem}>
            {formatNumber(menuItem.item.quantity)}
          </Text>
        </View>
        <View style={style.rowMobileCard}>
          <Text style={style.nameMobileCardItem}>
            Precio
          </Text>
          <Text style={style.valueMobileCardItem}>
            {menuItem.item.currency}   {formatNumber(menuItem.item.price)}
          </Text>
        </View>
        <View style={style.rowMobileCard}>
          <Text style={style.nameMobileCardItem}>
            Valor de Mercado
          </Text>
          <Text style={style.valueMobileCardItem}>
            {menuItem.item.currency}   {formatNumber(menuItem.item.value1)}
          </Text>
        </View>
        <View style={style.rowMobileCard}>
          <Text style={style.nameMobileCardItem}>
            Valor de Mercado {this.props.currency}
          </Text>
          <Text style={style.valueMobileCardItem}>
            {this.props.currency} {this.props.currency === ARS ? formatNumber(menuItem.item.totalArs) : formatNumber(menuItem.item.value2)}
          </Text>
        </View>
      </View>
    );
  }
  renderItem(menuItem) {
    return (
      <TextLine>
        <Text style={style.textLineItemStyle}>
          {menuItem.item.name}
        </Text>
        <Text style={style.centeredTextLineItemStyle}>
          {menuItem.item.positionDTO.estado}
        </Text>
        <Text style={style.rightTextLineItemStyle}>
          {formatNumber(menuItem.item.quantity)}
        </Text>
        <Text style={style.rightTextLineItemStyle}>
          {menuItem.item.currency}   {formatNumber(menuItem.item.price)}
        </Text>
        <Text style={style.rightTextLineItemStyle}>
          {menuItem.item.currency}   {formatNumber(menuItem.item.value1)}
        </Text>
        <Text style={style.rightTextLineItemStyle}>
          {this.props.currency} {this.props.currency === ARS ? formatNumber(menuItem.item.totalArs) : formatNumber(menuItem.item.value2)}
        </Text>
        <Text> </Text>
      </TextLine>
    );
  }

  renderData(data) {
    if (data && data.length != 0) {
      if (this.props.renderingMode === APPLICATION_RENDERING_MODE_MOBILE) {
        return (this.renderMobileTable(data));
      }
      else {
        return (this.renderTable(data));
      }
    } else {
      return (
        <View>
          <TextLine>
            <Text style={style.noDataFound}>No se encontraron datos.</Text>
          </TextLine>
        </View>
      );
    }

  }

  renderResults(data) {
    const { resultTextStyle, tcStyle } = style.resultsCardStyle;
    return (
      <View style={{ padding: 20 }}>
        <HighlightedText style={resultTextStyle}
          label={this.props.currency}
          value={this.getTotal(data.RESULTS)}
        />
        <SmallText style={tcStyle}>
          T.C.: {formatNumber(data.RESULTS.changeRatio)}
        </SmallText>
      </View>
    );
  }

  renderCards() {
    const { data } = this.props;
    this.loadData();

    if (this.props.loading) {
      return (
        <LoadingIndicator
          message="Esta operación puede tardar varios segundos"
        />
      );
    } else {
      if (this.props.error) {
        return (
          <CenteredErrorMessage
            message={this.props.error}
            renderingMode={this.props.renderingMode}
          />
        );
      } else if (data === undefined || data === null) {
        return (
          <CenteredErrorMessage
            message="No se pudo encontrar información para la cuenta actual."
            renderingMode={this.props.renderingMode}
          />
        );
      } else {
        const { CASH, FIXED, VARIABLE, OTHER } = data;
        const currency = this.props.currency;
        const chartType = this.props.chartType;

        return (
          <View style={this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN ? style.containerStyle  : style.mobileContainerStyle}>
            <RowContainer renderingMode={this.props.renderingMode}>
              <AdaptiveCard
                title="Total general"
                titleSider1={
                  <Selector
                    selectedValue={currency}
                    onValueChange={this.onCurrencyChange.bind(this)}
                  >
                    <Picker.Item label="ARS" value="ARS" />
                    <Picker.Item label="USD" value="USD" />
                  </Selector>}
                renderingMode={this.props.renderingMode}
                size="small"
              >
                {this.renderResults(data, currency)}
              </AdaptiveCard>
              <AdaptiveCard
                title="Composición"
                titleSider1={
                  <Selector
                    selectedValue={chartType}
                    onValueChange={this.onChartTypeChange.bind(this)}
                  >
                    <Picker.Item label="Tipo de Activo" value={CHART_TYPE_ACTIVE} />
                    <Picker.Item label="Moneda" value={CHART_TYPE_CURRENCY} />
                  </Selector>}
                renderingMode={this.props.renderingMode}
                size="medium"
              >
                <Image
                  style={this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN ? style.chartStyle : style.mobileChartStyle}
                  source={this.props.selectedChart}
                />
              </AdaptiveCard>
            </RowContainer>
            <AdaptiveCard
              title="Activos Líquidos"
              titleSider1={<Text style={style.textSiderStyle}>Participación en cartera  {formatNumber(CASH.percent)} %</Text>}
              titleSider2={<Text style={style.textSiderStyle}>Subtotal  {currency} {formatNumber(this.getTotal(CASH))}</Text>}
              renderingMode={this.props.renderingMode}
              collapsible
              size="large"
            >
              {this.renderData(CASH.positions)}
            </AdaptiveCard>
            <AdaptiveCard
              title="Renta Fija"
              titleSider1={<Text style={style.textSiderStyle}>Participación en cartera  {formatNumber(FIXED.percent)} %</Text>}
              titleSider2={<Text style={style.textSiderStyle}>Subtotal  {currency} {formatNumber(this.getTotal(FIXED))}</Text>}
              renderingMode={this.props.renderingMode}
              collapsible
              size="large"
            >
              {this.renderData(FIXED.positions)}
            </AdaptiveCard>
            <AdaptiveCard
              title="Renta Variable"
              titleSider1={<Text style={style.textSiderStyle}>Participación en cartera  {formatNumber(VARIABLE.percent)} %</Text>}
              titleSider2={<Text style={style.textSiderStyle}>Subtotal  {currency} {formatNumber(this.getTotal(VARIABLE))}</Text>}
              renderingMode={this.props.renderingMode}
              collapsible
              size="large"
            >
              {this.renderData(VARIABLE.positions)}
            </AdaptiveCard>
            <AdaptiveCard
              title="Derivados - Otros"
              titleSider1={<Text style={style.textSiderStyle}>Participación en cartera  {formatNumber(OTHER.percent)} %</Text>}
              titleSider2={<Text style={style.textSiderStyle}>Subtotal  {currency} {formatNumber(this.getTotal(OTHER))}</Text>}
              renderingMode={this.props.renderingMode}
              collapsible
              size="large"
            >
              {this.renderData(OTHER.positions)}
            </AdaptiveCard>
          </View>
        );
      }

    }
  }

  render() {
    return (
      <View style={this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN ? style.containerStyle : style.mobileContainerStyle}>
        <Column>
          <EmptyAdaptiveCard
            size={LARGE}
            renderingMode={this.props.renderingMode}  >
            <DateSelector
              label={this.props.selectedDate.toString() == format(new Date())?"Fecha de Concertación":"Fecha de Concertación *"}
              onChange={(date) => this.props.changeSelectedDate(date)} />
            <Text style={this.props.selectedDate.toString() == format(new Date())?style.hiddenText:style.smallText}>* Sólo aplica a posiciones locales</Text>
           </EmptyAdaptiveCard>
          {this.renderCards()}
        </Column>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.account.selectedAccount,
    currency: state.portfolio.currency,
    chartType: state.portfolio.chartType,
    selectedAccount: state.portfolio.selectedAccount,
    selectedDate: state.portfolio.selectedDate,
    data: state.portfolio.data,
    error: state.portfolio.error,
    loading: state.portfolio.loading,
    renderingMode: state.application.renderingMode,
    selectedChart: state.portfolio.chartType === CHART_TYPE_ACTIVE ?
      state.portfolio.compositionChartData :
      state.portfolio.currencyChartData
  };
};

export default connect(mapStateToProps, { switchCurrency, switchChartType, updateData, noAccountSelected, invalidateAccount, changeSelectedDate })(PortfolioForm);
