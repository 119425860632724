import * as colors from '../../styles/colors';

export const CenteredErrorMessageStyle = {
  containerStyle: {
    flex: 1,
    padding: 10
  },
  noAccountMessageStyle: {
    marginTop: 25,
    marginStart: -200,
    color: colors.SYC_GRAY_CARD_TITLE,
    alignSelf: 'center'
  },
  mobileNoAccountMessageStyle: {
    marginTop: 25,
    marginStart: 1,
    color: colors.SYC_GRAY_CARD_TITLE,
    alignSelf: 'center'
  }
};