import { MENU_ID_SELECTED } from './types';
import { ACTIONS_DOWNLOAD_PDF_REPORT, ACTIONS_CHANGE_PASSWORD } from '../../../actions/redux/types';
import { INITIAL_STATE } from './initialState';

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MENU_ID_SELECTED:
            return { ...state, selectedMenu: action.payload };
        case ACTIONS_DOWNLOAD_PDF_REPORT:
            return { ...state, selectedMenu: 6 };
        case ACTIONS_CHANGE_PASSWORD:
            return { ...state, selectedMenu: 7 };
        default:
            return state;
    }
}