import * as colors from '../colors';

export const AdaptiveCardStyle = {
  containerStyle: {
    backgroundColor: colors.SYC_WHITE,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 5,
    margin: 10,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.SYC_LIGHT_GRAY,
    borderBottomWidth: 0,
    shadowColor: colors.BLACK,
    shadowRadius: 5,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.2,
    elevation: 2
  },
  headerStyle: {
    padding: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  contentStyle: {
    padding: 5,
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 8,
  },
  scrollableContentStyle: {
    padding: 5,
    flexDirection: 'row',
    justifyContent: 'start',
    width: 'calc(100vw - 20px)',
    overflow:'scroll',
  },
  mobileContainerStyle: {
    width: 'calc(100vw - 20px)',
    backgroundColor: colors.SYC_WHITE,
    flexDirection: 'column',
    marginTop: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.SYC_LIGHT_GRAY,
    borderBottomWidth: 0,
    shadowColor: colors.BLACK,
    shadowRadius: 5,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.2,
    elevation: 1
  },
  endingStyle: {
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  arrowStyle: {
    margin: 5,
    width: '1.3rem',
    height: '1.3rem'
  },
  pinContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: 5,
    display: 'none'
  },
  pinStyle: {
    width: '1rem',
    height: '1rem'
  },
  titleStyle: {
    flex: 1,
    fontSize: '1.1rem',
    marginStart: '0.5rem',
    alignSelf: 'center',
    color: colors.SYC_GRAY_CARD_TEXT
  }
}