import * as colors from '../../../styles/colors';

export const PortfolioFormStyle = {
  containerStyle: {
    flex: 1,
    padding: 10
  },
  mobileContainerStyle: {
    flex: 1
  },
  headerTextLineItemStyle: {
    textAlign: 'center',
    flex: 1,
    fontWeight: 'bold',
    alignItems: 'flex-end'
  },
  headerTextLineItemStyleL: {
    textAlign: 'left',
    flex: 1,
    fontWeight: 'bold',
    alignItems: 'flex-end'
  },
  headerTextLineItemStyleR: {
    textAlign: 'right',
    flex: 1,
    fontWeight: 'bold',
    alignItems: 'flex-end'
  },
  textLineItemStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  centeredTextLineItemStyle: {
    flex: 1,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  leftTextLineItemStyle: {
    flex: 1,
    textAlign: 'left',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  rightTextLineItemStyle: {
    flex: 1,
    textAlign: 'right',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  textSiderStyle: {
    flex: 1,
    fontSize:'0.8rem',
    textAlign:'center'
  },

  chartStyle: {
    width: '95%',
    height: 300,
    alignSelf: 'center',
    resizeMode: 'contain'
  },
  mobileChartStyle: {
    width: '92vw',
    height: 300,
    alignSelf: 'center',
    resizeMode: 'contain'
  },
  resultsCardStyle: {
    resultTextStyle: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 25
    },
    tcStyle: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  },
  noAccountMessageStyle: {
    marginTop: 25,
    marginStart: -200,
    color: colors.SYC_GRAY_CARD_TITLE,
    alignSelf: 'center'
  },
  loadingTextStyle: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10
  },
  noDataFound: {
    fontStyle: 'italic',
    color: colors.SYC_GRAY_CARD_TEXT
  },
  mobileCardItem:{
    borderTopColor: colors.SYC_LIGHT_GRAY,
    borderTopWidth: 1,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem'
  },
  nameMobileCardItem:{
    flex:3,
    textAlign: 'left',
    fontWeight: 'bold',
  },
  valueMobileCardItem:{
    flex:5,
    textAlign: 'right',
  },
  headMobileCardItem:{
    flex:8,
    fontWeight: 'bold',
    textAlign: 'center',
    borderBottomColor: colors.SYC_LIGHT_GRAY,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid'
  },
  rowMobileCard:{
    padding: 5,
    marginStart:10,
    marginEnd:10,
    flexDirection: 'row',
  },
  smallText:{
	    fontSize:'0.8rem',
  },
  hiddenText:{
	    display:'none',
  }
}