import moment from 'moment';

export const format = (date, pattern) => {
  if (typeof date === 'string') {
    return date;
  } else {
    const pattr = pattern || 'DD/MM/YYYY';
    return moment(date).format(pattr);
  }
};

export const parse = (date, pattern) => {
  const pattr = pattern || 'DD/MM/YYYY';
  return moment(date, pattr);
}

export const now = () => {
  return new Date();
};

export const yesterday = () => {
  var yesterday = new Date();
  yesterday.setDate(now().getDate() - 1);
  return yesterday;
};

export const firstDayOfYear = () => {
  var firstDayOfYear = new Date(now().getFullYear(), 0, 1);
  return firstDayOfYear;
};

export const oneWeekBefore = () => {
  var oneWeekBefore = new Date();
  oneWeekBefore.setDate(now().getDate() - 7);
  return oneWeekBefore;
}