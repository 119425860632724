import {
  ACTIONS_ACCOUNTS_FETCH
} from './types';
import { INITIAL_STATE } from './initialState';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS_ACCOUNTS_FETCH:
      return {
        ...state,
        accounts: action.payload
      }
    default:
      return state;
  }
}