import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { ExternalFormStyle as style } from './style/ExternalForm';
import { requestAccess } from './redux/actions';
import { LoadingIndicator } from '../../components/common/LoadingIndicator';
import { RECEIPTS_URL } from '../../service/Endpoints';

class ExternalForm extends Component {

  componentWillMount() {
    this.props.requestAccess(RECEIPTS_URL);
  }
  
  loadIFrame = () => {
    this.refs.iframe.src = this.props.source;
  }
  
  getMainView() {
    if (this.props.loading) {
      return (
        <LoadingIndicator
          message="Esta operación puede tardar varios segundos"
        />
      );
    } else {
      if (this.props.source && !this.props.error) {
        setTimeout(this.loadIFrame);
        return (
          <View style={style.frameContainerStyle}>
            <iframe ref="iframe" title="web" style={{ height: '100%', width: '100%', border: 0, seamless: true }} ></iframe>
          </View>
        );
      } else {
        return (
          <View style={style.containerStyle}>
            <Text style={style.unauthorizedMessageStyle}>
              {this.props.error}
            </Text>
          </View>
        );
      }
    }
  }

  render() {
    return (
      this.getMainView()
    );
  }
}

const mapStateToProps = state => {
  return {
    source: state.external.source,
    error: state.external.error,
    loading: state.external.loading
  };
};

export default connect(mapStateToProps, { requestAccess })(ExternalForm);