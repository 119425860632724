export const ACTIONS_SHOW_USER_PANE = 'show_user';
export const ACTIONS_SHOW_ACCOUNTS_PANE = 'show_accounts';
export const ACTIONS_DOWNLOAD_PDF_REPORT = 'download_pdf_report';
export const ACTIONS_CHANGE_PASSWORD = 'change_password';
export const ACTIONS_USER_LOGOUT = 'logout';
export const ACTIONS_USER = [
  ACTIONS_CHANGE_PASSWORD, 
  ACTIONS_USER_LOGOUT
];
export const ACTIONS_SELECT_ACCOUNT = 'select_account';
export const ACTIONS_ACCOUNTS_FETCH = 'accounts_fetch';
