import React, { Component } from 'react';
import { View, FlatList, Text, Picker, Image, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { TransactionsFormStyle as style } from './style/TransactionsForm';
import { format } from '../../utils/DateUtils';
import { ellipsize } from '../../utils/StringUtils';
import {
  EmptyAdaptiveCard,
  AdaptiveCard,
  TextLine,
  HighlightedText,
  DateSelector,
  Selector,
  CenteredErrorMessage,
  Column,
} from '../../components/common';
import {
  invalidateAccount,
  updateData,
  changeSelectedDate,
  changeSelectedSpecie,
  downloadExcel
} from './redux/actions';
import {
  TRANSACTIONS_START_DATE,
  TRANSACTIONS_END_DATE,
  TRANSACTIONS_SELECTED_SPECIE,
  SPECIE_TYPE_CURRENCY
} from './redux/constants';
import { LoadingIndicator } from '../../components/common/LoadingIndicator';
import { LARGE } from '../../components/common/constants';
import {
  APPLICATION_RENDERING_MODE_MOBILE as mobile,
  APPLICATION_RENDERING_MODE_SCREEN as screen
} from '../common/application/redux/constants';

class TransactionsForm extends Component {

  invalidateSelectedAccount() {
    if (this.props.account !== this.props.selectedAccount) {
      this.props.invalidateAccount(this.props.account);
    }
  }

  loadData(account, startDate, endDate, specie, entitlementType, data) {
    this.invalidateSelectedAccount(account);
    if (account.accountId !== undefined) {
      if (data === null) {
        this.props.updateData(account.accountId, format(startDate), format(endDate), specie, entitlementType);
      }
    } else {
      this.props.noAccountSelected();
    }
  }

  onSelectedSpecieChange(value) {
    this.props.changeSelectedSpecie(value);
  }

  onDownload() {
    this.props.downloadExcel(
      this.props.account.accountId,
      this.props.startDate,
      this.props.endDate,
      this.props.specie,
      this.props.entitlementType
    );
  }



  renderSpeciesSelector() {
    const { data } = this.props;
    let speciesInSelector = [];

    speciesInSelector.push({ value: TRANSACTIONS_SELECTED_SPECIE, label: TRANSACTIONS_SELECTED_SPECIE });

    if (data !== undefined && data !== null) {
      data.forEach(element => {
        element.label = ellipsize(element.specie.nombre, 30);
        element.value = element.specie.nombre
        speciesInSelector.push(element)
      });
    }

    return (
      <View style={style.selectorStyle}>
        <Selector
          selectedValue={this.props.selectedSpecie}
          onValueChange={this.onSelectedSpecieChange.bind(this)}
        >
          {speciesInSelector.map((specieInSelector, index) => {
            return <Picker.Item label={specieInSelector.label} value={specieInSelector.value} />;
          })}
        </Selector>
      </View>
    );
  }

  renderExcelDownloadButton() {
    if (this.props.renderingMode === mobile)
      return <View />;
    else
      return (
        <TouchableOpacity style={style.excelDowloadButtonStyle}
          onPress={this.onDownload.bind(this)}
        >
          <Image
            source={require('./assets/Excel.svg')}
            style={{ height: 35, width: 35 }}
          />
        </TouchableOpacity>
      );
  }

  renderCards() {
    const { account, startDate, endDate, specie, entitlementType, data } = this.props;
    this.loadData(account, startDate, endDate, specie, entitlementType, data);

    if (this.props.loading) {
      return (
        <LoadingIndicator
          message="Esta operación puede tardar varios segundos"
        />
      )
    } else {
      if (data === undefined || data === null) {
        return (
          <CenteredErrorMessage
            message="No se pudo encontrar información para la cuenta actual."
            renderingMode={this.props.renderingMode}
          />
        );
      } else {
        let sectionData = this.mapDataToListData(data);
        return (
          <FlatList
            style={style.tableStyle}
            data={sectionData}
            renderItem={this.renderInstrumentCard.bind(this)}
            keyExtractor={(item, index) => index}
          />
        );
      }
    }
  }

  renderInstrumentCard(instrument) {
    return (
      <AdaptiveCard
        collapsible
        title={instrument.item.title}
        titleSider1={
          <Text style={style.currencyStyle}>
            {instrument.item.currency}
          </Text>
        }
        renderingMode={this.props.renderingMode}
        size="large"
        scrollableContent={true}
      >
        <View >
          <TextLine style={style.tableRowStyle}>
            <Text style={style.bigheaderTextLineItemStyle}>
              Información
            </Text>
            <Text style={style.headerTextLineItemStyle}>
              Fecha
            </Text>
            <Text style={style.headerTextLineItemStyle}>
              Monto
            </Text>
            <Text style={style.headerTextLineItemStyle}>
              Saldo
            </Text>
          </TextLine>
          <FlatList
            style={style.tableRowStyle}
            data={instrument.item.data}
            renderItem={this.renderInstrumentTransaction}
            keyExtractor={(item, index) => index}
          />
        </View>
      </AdaptiveCard>
    );
  }

  renderInstrumentTransaction(transaction) {
    return (
      <TextLine style={{ flex: 1 }}>
        <Text style={style.textLineItemStyle}>
          {transaction.item.informacion}
        </Text>
        <Text style={style.centeredTextLineItemStyle}>
          {transaction.item.fecha}
        </Text>
        <View style={style.centeredTextLineItemStyle}>
          <HighlightedText
            value={transaction.item.cantidad}
            size={14}
          />
        </View>
        <View style={style.centeredTextLineItemStyle}>
          <HighlightedText
            value={transaction.item.saldo}
            size={14}
          />
        </View>
      </TextLine>
    );
  }

  mapDataToListData(rawData) {
    let result = rawData
      .filter(value => value.specie.nombre === this.props.selectedSpecie ||
        this.props.selectedSpecie === TRANSACTIONS_SELECTED_SPECIE)
      .map((value) => {
        const currency = value.specie.tipo === SPECIE_TYPE_CURRENCY ? value.specie.representacion : value.specie.monedaCotizacion;
        return {
          title: value.specie.nombre,
          currency: currency,
          data: value.transactions
        };
      });
    return result;
  }

  render() {
    return (
      <View style={this.props.renderingMode === screen ? style.containerStyle : style.mobileContainerStyle}>
        <Column>
          <EmptyAdaptiveCard
            size={LARGE}
            renderingMode={this.props.renderingMode}>
            <View style={style.filterStyle}>
              <DateSelector
                rangePick
                renderingMode={this.props.renderingMode}
                labelStart="Fecha desde:"
                labelEnd="Fecha hasta:"
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                onChangeStart={(date) => { this.props.changeSelectedDate(date, TRANSACTIONS_START_DATE) }}
                onChangeEnd={(date) => { this.props.changeSelectedDate(date, TRANSACTIONS_END_DATE) }}
              />
              {this.renderSpeciesSelector()}
              {this.renderExcelDownloadButton()}
            </View>
          </EmptyAdaptiveCard>
          {this.renderCards()}
        </Column>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.account.selectedAccount,
    renderingMode: state.application.renderingMode,
    selectedSpecie: state.transactions.selectedSpecie,
    loading: state.transactions.loading,
    data: state.transactions.data,
    selectedAccount: state.transactions.selectedAccount,
    startDate: state.transactions.startDate,
    endDate: state.transactions.endDate,
    specie: state.transactions.specie,
    entitlementType: state.transactions.entitlementType
  };
};

export default connect(mapStateToProps, { changeSelectedSpecie, updateData, invalidateAccount, changeSelectedDate, downloadExcel })(TransactionsForm);