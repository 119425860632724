import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { WelcomeFormStyle as style } from './style/WelcomeForm';

class WelcomeForm extends Component {

  getWelcomeMessage() {
    return `Bienvenido a la nueva WebApp!
          
          Esperamos encuentres el nuevo diseño mas intuitivo, para comenzar simplemente hace click en algunas de las secciones a tu izquierda!

          En caso de tener más de una cuenta comitente podes seleccionarla en el listado de arriba a la derecha.

          Para ver un pequeño tutorial ingresar a este <a href="https://sycinversiones.com/tutorial-app" target="_blank">link</a>.

          Ante cualquier duda o comentario por favor escribimos a cuentas@sycinversiones.com y responderemos a la brevedad para asistirte!`;
  }

  render() {
    return ( 
      <View style={style.containerStyle}>
          <div style={style.textStyle}>
          <p>Bienvenido a la nueva WebApp!</p>
          <p>Esperamos encuentres el nuevo diseño mas intuitivo, para comenzar simplemente hace click en algunas de las secciones a tu izquierda!</p>
          <p>En caso de tener más de una cuenta comitente podes seleccionarla en el listado de arriba a la derecha.</p>
          <p>Para ver un pequeño tutorial ingresar a este <a href="https://sycinversiones.com/tutorial-app" target="_blank">link</a>.</p>
          <p>Ante cualquier duda o comentario por favor escribimos a cuentas@sycinversiones.com y responderemos a la brevedad para asistirte!</p>
          </div>
      </View> 
    );
  }
}

export default connect()(WelcomeForm);
