import { CHART_TYPE_PERFORMANCE, ARS } from "./constants";
import { yesterday, firstDayOfYear } from "../../../utils/DateUtils";

export const INITIAL_STATE = {
  currency: ARS,
  loading: true,
  selectedAccount: '',
  data: null,
  changeRatio: 0.0,
  startDate: firstDayOfYear(),
  endDate: yesterday(),
  chartType: CHART_TYPE_PERFORMANCE,
  accumulatedChartData: null,
  monthlyChartData: null,
  performanceChartData: null
};
