import * as colors from '../../../styles/colors';

export const WelcomeFormStyle = {
  containerStyle: {
    flex: 1,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 60px)'
  },
  textStyle: {
    fontSize: 14, 
    color: colors.SYC_GRAY_CARD_TEXT,
    textAlign: 'center'
  }
};