import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  TouchableWithoutFeedback
} from 'react-native';
import { AdaptiveCardStyle as style } from '../../styles/common/AdaptiveCard';
import {
  APPLICATION_RENDERING_MODE_MOBILE,
  APPLICATION_RENDERING_MODE_SCREEN
} from '../../features/common/application/redux/constants';
import {
  MEDIUM,
  SMALL,
  LARGE,
  CARD_SIZE_LARGE,
  CARD_SIZE_MEDIUM,
  CARD_SIZE_SMALL,
  BASE_WIDTH,
} from './constants'

class AdaptiveCard extends Component {

  state = {
    collapsed: this.props.collapsible || false
  };

  getCardStyle(baseStyle) {
    switch (this.props.size) {
      case LARGE:
        return { ...baseStyle, width: BASE_WIDTH + CARD_SIZE_LARGE };
      case MEDIUM:
        return { ...baseStyle, width: BASE_WIDTH + CARD_SIZE_MEDIUM };
      case SMALL:
          return { ...baseStyle, width: BASE_WIDTH + CARD_SIZE_SMALL };
      default:
        return { ...baseStyle, width: BASE_WIDTH + CARD_SIZE_SMALL };
    }
  }

  getRenderingStyle() {
    if (this.props.renderingMode === APPLICATION_RENDERING_MODE_MOBILE) {
      return style.mobileContainerStyle;
    } else if (this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN) {
      return this.getCardStyle(style.containerStyle);
    }
  }

  getRenderingContentStyle() {
    if (this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN) {
      return this.getCardStyle(style.contentStyle);
    } else if (this.props.scrollableContent && this.props.renderingMode === APPLICATION_RENDERING_MODE_MOBILE) {
      return style.scrollableContentStyle;
    }
  }

  renderEnding() {
    if (this.props.collapsible) {
      return (
        <TouchableWithoutFeedback
          style={style.endingStyle}
          onPress={() => this.setState({ collapsed: !this.state.collapsed })}
        >
          <Image
            source={this.getArrowIcon(this.state.collapsed)}
            style={style.arrowStyle}
          />
        </TouchableWithoutFeedback>
      );
    }
  }

  getArrowIcon(collapsed) {
    if (collapsed) {
      return require('../../assets/icons/Flecha_Abajo.svg');
    } else {
      return require('../../assets/icons/Flecha_Arriba.svg');
    }
  }

  renderContent(collapsed) {
    if (collapsed) {
      return <View />;
    } else {
      return (
        <View style={this.getRenderingContentStyle()}>
          {this.props.children}
        </View>
      );
    }
  }

  render() {
    return (
      <View style={this.getRenderingStyle()}>
        <View style={style.headerStyle}>
          <Text style={style.titleStyle}>
            {this.props.title}
          </Text>
          {this.props.titleSider1}
          {this.props.titleSider2}
          {this.renderEnding()}
        </View>
        {this.renderContent(this.state.collapsed)}
        <View style={style.pinContainerStyle}>
          <Image
            source={require('../../assets/icons/Pin.svg')}
            style={style.pinStyle}
          />
        </View>
      </View>
    );
  }
}

export { AdaptiveCard };
