import * as colors from '../colors';

export const DateSelectorStyle = {
  mainContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5,
    zIndex: 2
  },
  mobileMainContainerStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    margin: 5,
    zIndex: 2
  },
  containerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5,
    zIndex: 2
  },
  labelStyle: {
    color: colors.SYC_GRAY_CARD_TITLE,
    marginEnd: 5,
    elevation: 4
  },
  boxStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.SYC_MENU_ITEM_TEXT,
    padding: 2,
    margin: 5,
    backgroundColor: colors.SYC_WHITE,
    elevation: 4
  }, 
  textStyle: {
    color: colors.SYC_GRAY_CARD_TITLE,
    marginStart: 5,
    marginEnd: 5,
    elevation: 4
  },
  imageStyle: {
    width: 20,
    height: 20,
    margin: 5
  }
}