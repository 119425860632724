import { INITIAL_STATE } from "./initialState";
import { 
  TOGGLE_REPORT, 
  CHANGE_DATE, 
  CHANGE_CURRENCY, 
  PDF_DOWNLOAD_STATE, 
  PDF_DOWNLOAD_ERROR,
    OPEN_MODAL,
    CLOSE_MODAL,
      PDF_DOWNLOAD_NO_OPTIONS_SELECTED
} from "./types";
import {
  REPORT_PORTFOLIO,
  REPORT_PERFORMANCE,
  REPORT_TRANSACTIONS,
  PORTFOLIO_AGREEMENT_DATE,
  PERFORMANCE_DATE_FROM,
  PERFORMANCE_DATE_TO,
  PORTFOLIO_CURRENCY,
  PERFORMANCE_CURRENCY,
  TRANSACTIONS_DATE_FROM,
  TRANSACTIONS_DATE_TO
} from "./constants";

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_REPORT + REPORT_PORTFOLIO:
      return { ...state, usePortfolio: !state.usePortfolio };
    case TOGGLE_REPORT + REPORT_PERFORMANCE:
      return { ...state, usePerformance: !state.usePerformance };
    case TOGGLE_REPORT + REPORT_TRANSACTIONS:
      return { ...state, useTransactions: !state.useTransactions };
    case CHANGE_DATE + PORTFOLIO_AGREEMENT_DATE:
      return { ...state, portfolioAgreementDate: action.payload };
    case CHANGE_DATE + PERFORMANCE_DATE_FROM:
      return { ...state, performanceDateFrom: action.payload };
    case CHANGE_DATE + PERFORMANCE_DATE_TO:
      return { ...state, performanceDateTo: action.payload };
    case CHANGE_CURRENCY + PORTFOLIO_CURRENCY:
      return { ...state, portfolioCurrency: action.payload };
    case CHANGE_CURRENCY + PERFORMANCE_CURRENCY:
      return { ...state, performanceCurrency: action.payload };
    case CHANGE_DATE + TRANSACTIONS_DATE_FROM:
      return { ...state, transactionsDateFrom: action.payload };
    case CHANGE_DATE + TRANSACTIONS_DATE_TO:
      return { ...state, transactionsDateTo: action.payload };
    case PDF_DOWNLOAD_STATE:
      return { ...state, loading: action.payload, error: "" };
    case PDF_DOWNLOAD_ERROR:
      return { ...state, error: action.payload };
    case PDF_DOWNLOAD_NO_OPTIONS_SELECTED:
      return { ...state, error: action.payload };
    case  OPEN_MODAL:
      return { ...state, showModal: true, modalMessage: action.payload };
    case CLOSE_MODAL:
      return { ...state, showModal: false, modalMessage: '' };
    default:
      return state;
  }
};