import React, { Component } from 'react';
import { View, Text, Picker, FlatList, Image } from 'react-native';
import { connect } from 'react-redux';
import { PerformanceFormStyle as style } from './style/PerformanceForm';
import {
  EmptyAdaptiveCard,
  DateSelector,
  RowContainer,
  AdaptiveCard,
  Selector,
  TextLine,
  HighlightedText,
  SmallText,
  CenteredErrorMessage,
  Column
} from '../../components/common';
import {
  CHART_TYPE_PERFORMANCE,
  CHART_TYPE_ACCUMULATED,
  CHART_TYPE_MONTHLY,
  ARS,
  PERFORMANCE_START_DATE,
  PERFORMANCE_END_DATE
} from './redux/constants';
import {
  switchChartType,
  switchCurrency,
  invalidateAccount,
  changeSelectedDate,
  updateData
} from './redux/actions';
import { format, yesterday } from '../../utils/DateUtils';
import { formatNumber } from '../../utils/NumberUtils';
import { LoadingIndicator } from '../../components/common/LoadingIndicator';
import { APPLICATION_RENDERING_MODE_SCREEN, APPLICATION_RENDERING_MODE_MOBILE } from '../common/application/redux/constants';
import { LARGE } from '../../components/common/constants';

class PerformanceForm extends Component {

  invalidateSelectedAccount() {
    if (this.props.account !== this.props.selectedAccount) {
      this.props.invalidateAccount(this.props.account);
    }
  }

  loadData(account, startDate, endDate, currency, data) {
    this.invalidateSelectedAccount(account);
    if (account.accountId !== undefined) {
      if (data === null) {
        this.props.updateData(account, format(startDate), format(endDate), currency);
      }
    } else {
      this.props.noAccountSelected();
    }
  }

  onChartTypeChange(value, index) {
    this.props.switchChartType(value);
  }

  onCurrencyChange(value, index) {
    this.props.switchCurrency(value);
  }

  renderTotal() {
    return (
      <View style={style.resultCard.containerStyle}>
        <HighlightedText
          style={style.resultCard.textCardStyle}
          label={this.props.currency}
          value={this.props.data.result}
        />
      </View>
    );
  }

  renderPerformance() {
    return (
      <View style={style.resultCard.containerStyle}>
        <HighlightedText
          style={style.resultCard.textCardStyle}
          label={this.props.currency}
          value={this.props.data.globalPerformance}
        />
        <HighlightedText
          style={style.resultCard.textCardStyle}
          label="%"
          value={this.props.data.performancePercent * 100}
        />
      </View>
    );
  }

  renderChart() {
    return (
      <Image
        style={this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN ? style.chartStyle : style.mobileChartStyle}
        source={this.props.selectedChart}
      />
    );
  }
  getTextSize() {
    if (this.props.renderingMode === APPLICATION_RENDERING_MODE_MOBILE) {
      return (style.mobileTableText);
    } else {
      return (style.normalTableText);
    }
  }
  getBottonTitleTextSize() {
    if (this.props.renderingMode === APPLICATION_RENDERING_MODE_MOBILE) {
      return (style.mobileBottomTitleText);
    } else {
      return (style.normalBottomTitleText);
    }
  }
  renderTableRow(row, currency) {
    return (
      <TextLine>
        <Text style={[style.textLineItemStyle, this.getTextSize()]}>
          {row.item.month}
        </Text>
        <Text style={[style.textLineItemStyle, this.getTextSize()]}>
          {currency} {formatNumber(row.item.startPosition)}
        </Text>
        <Text style={[style.textLineItemStyle, this.getTextSize()]}>
          {currency} {formatNumber(row.item.endPosition)}
        </Text>
        <Text style={[style.textLineItemStyle, this.getTextSize()]}>
          {currency} {formatNumber(row.item.transactions)}
        </Text>
        <Text style={[style.textLineItemStyle, this.getTextSize()]}>
          {currency} {formatNumber(row.item.result)}
        </Text>
        <Text style={[style.textLineItemStyle, this.getTextSize()]}>
          {formatNumber(row.item.percentResult * 100)} %
        </Text>
      </TextLine>
    );
  }

  renderTable() {
    return (
      <View style={style.tableStyle} >
        <TextLine>
          <Text style={[style.headerTextLineItemStyle, this.getTextSize()]}>
            Mes
          </Text>
          <Text style={[style.headerTextLineItemStyle, this.getTextSize()]}>
            Posición inicial
          </Text>
          <Text style={[style.headerTextLineItemStyle, this.getTextSize()]}>
            Posición final
          </Text>
          <Text style={[style.headerTextLineItemStyle, this.getTextSize()]}>
            Movimientos
          </Text>
          <Text style={[style.headerTextLineItemStyle, this.getTextSize()]}>
            Ganancia / Pérdida
          </Text>
          <Text style={[style.headerTextLineItemStyle, this.getTextSize()]}>
            % Mensual
          </Text>
        </TextLine>
        <FlatList
          style={style.tableListStyle}
          data={this.props.data.historicPerformance}
          renderItem={(row) => this.renderTableRow(row, this.props.currency)}
          keyExtractor={(item) => item.month}
        />
        {this.renderBottomTableRow()}
      </View>
    );
  }
  renderBottomTableRow() {
    return (
      <View style={style.bottomTableRowStyle}>
        <TextLine>
          <Text style={[style.bottomTableTitleStyle, this.getBottonTitleTextSize()]}>
            Total
          </Text>
          <Text style={[style.textLineItemStyle, this.getTextSize()]}>
            {this.props.currency} {formatNumber(this.props.data.globalTransactions)}
          </Text>
          <Text style={[style.textLineItemStyle, this.getTextSize()]}>
            {this.props.currency} {formatNumber(this.props.data.globalPerformance)}
          </Text>
          <Text style={[style.textLineItemStyle, this.getTextSize()]}>
            {formatNumber(this.props.data.performancePercent * 100)} %
           </Text>
        </TextLine>
      </View>
    );
  }
  renderCards() {
    const { account, startDate, endDate, currency, data } = this.props;
    this.loadData(account, startDate, endDate, currency, data);

    if (this.props.loading) {
      return (
        <LoadingIndicator
          message="Esta operación puede tardar varios segundos"
        />
      )
    } else {
      if (data === undefined || data === null) {
        return (
          <CenteredErrorMessage
            message="No se pudo encontrar información para la cuenta actual."
            renderingMode={this.props.renderingMode}
          />
        );
      } else {
        return (
          <View style={this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN ? style.containerStyle : style.mobileContainerStyle}>
            <RowContainer renderingMode={this.props.renderingMode}>
              <AdaptiveCard
                title="Total general"
                renderingMode={this.props.renderingMode}
                size="medium"
              >
                {this.renderTotal()}
              </AdaptiveCard>
              <AdaptiveCard
                title="Rendimiento general"
                renderingMode={this.props.renderingMode}
                size="small"
              >
                {this.renderPerformance()}
              </AdaptiveCard>
            </RowContainer>
            <AdaptiveCard
              title="Visión general"
              titleSider1={
                <Selector
                  selectedValue={this.props.chartType}
                  onValueChange={this.onChartTypeChange.bind(this)}
                >
                  <Picker.Item label={CHART_TYPE_PERFORMANCE} value={CHART_TYPE_PERFORMANCE} />
                  <Picker.Item label={CHART_TYPE_ACCUMULATED} value={CHART_TYPE_ACCUMULATED} />
                  <Picker.Item label={CHART_TYPE_MONTHLY} value={CHART_TYPE_MONTHLY} />
                </Selector>
              }
              renderingMode={this.props.renderingMode}
              size="large"
            >
              {this.renderChart()}
            </AdaptiveCard>
            <AdaptiveCard
              title="Visión general"
              renderingMode={this.props.renderingMode}
              size="large"
              scrollableContent={true}
            >
              {this.renderTable()}
            </AdaptiveCard>
          </View>
        );
      }
    }
  }

  render() {
    return (
      <View style={style.containerStyle}>
        <Column>
          <EmptyAdaptiveCard
            size={LARGE}
            renderingMode={this.props.renderingMode}>
            <DateSelector
              rangePick
              renderingMode={this.props.renderingMode}
              labelStart="Fecha desde:"
              labelEnd="Fecha hasta:"
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              maxDate={yesterday()}
              onChangeStart={(date) => { this.props.changeSelectedDate(date, PERFORMANCE_START_DATE) }}
              onChangeEnd={(date) => { this.props.changeSelectedDate(date, PERFORMANCE_END_DATE) }}
            />
            <View style={style.headingEndStyle}>
              <Selector
                selectedValue={this.props.currency}
                onValueChange={this.onCurrencyChange.bind(this)}
              >
                <Picker.Item label={ARS} value={ARS} />
                <Picker.Item label="USDB" value="USDB" />
              </Selector>
              <SmallText>
                T.C.: {formatNumber(this.props.changeRatio)}
              </SmallText>
            </View>
          </EmptyAdaptiveCard>
          {this.renderCards()}
        </Column>
      </View>
    );
  }
}

const mapStateToProps = state => {
  let chart;
  switch (state.performance.chartType) {
    case CHART_TYPE_ACCUMULATED:
      chart = state.performance.accumulatedChartData;
      break;
    case CHART_TYPE_MONTHLY:
      chart = state.performance.monthlyChartData;
      break;
    case CHART_TYPE_PERFORMANCE:
    default:
      chart = state.performance.performanceChartData;
      break;
  }
  return {
    account: state.account.selectedAccount,
    selectedAccount: state.performance.selectedAccount,
    currency: state.performance.currency,
    chartType: state.performance.chartType,
    loading: state.performance.loading,
    data: state.performance.data,
    changeRatio: state.performance.changeRatio,
    startDate: state.performance.startDate,
    endDate: state.performance.endDate,
    renderingMode: state.application.renderingMode,
    selectedChart: chart
  };
};

export default connect(mapStateToProps, { switchChartType, switchCurrency, changeSelectedDate, invalidateAccount, updateData })(PerformanceForm);
