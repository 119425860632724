import { yesterday, now } from '../../../utils/DateUtils';
import { TRANSACTIONS_SELECTED_SPECIE } from './constants';

export const INITIAL_STATE = {
  loading: true,
  selectedAccount: '',
  data: null,
  startDate: yesterday(),
  endDate: now(),
  specie: '',
  entitlementType: '',
  selectedSpecie: TRANSACTIONS_SELECTED_SPECIE
};