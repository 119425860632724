import { 
  TRANSACTIONS_SWITCH_ACCOUNT, 
  TRANSACTIONS_DATA_UPDATE, 
  TRANSACTIONS_DATA_UPDATE_FAILURE, 
  TRANSACTIONS_SWITCH_DATE, 
  TRANSACTIONS_SWITCH_SPECIE
} from "./types";
import { retrieve } from "../../../storage/Storage";
import { TOKEN } from "../../../storage/keys";
import { TRANSACTIONS_URL, EXCEL_TRANSACTIONS_URL } from "../../../service/Endpoints";
import {
  TRANSACTIONS_ACCOUNT_PARAM,
  TRANSACTIONS_START_DATE_PARAM,
  TRANSACTIONS_END_DATE_PARAM,
  TRANSACTIONS_SPECIE_PARAM,
  TRANSACTIONS_ENTITLEMENT_TYPE_PARAM,
  EXCEL_TRANSACTIONS_FILENAME
} from './constants';
import { dispatchLogoutProcess } from "../../actions/redux/actions";
import { format } from '../../../utils/DateUtils';

export const invalidateAccount = (newAccount) => {
  return {
    type: TRANSACTIONS_SWITCH_ACCOUNT,
    payload: newAccount
  };
};

export const changeSelectedDate = (date, dateType) => {
  return {
    type: TRANSACTIONS_SWITCH_DATE + dateType,
    payload: date
  };
};

export const changeSelectedSpecie = (specie) => {
  return {
    type: TRANSACTIONS_SWITCH_SPECIE, 
    payload: specie
  }
};

export const downloadExcel = (
  account, 
  startDate, 
  endDate, 
  specie, 
  entitlementType
) => {
  return (dispatch) => {
    retrieve(TOKEN)
    .then(token => fetch(EXCEL_TRANSACTIONS_URL + '?' +
      addParameters(
        account, 
        format(startDate), 
        format(endDate), 
        specie, 
        entitlementType), {
        method: 'GET', 
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Auth-Token': token
        }
      }))
      .then(response => dispatchDownload(dispatch, response))
  };
};

const dispatchDownload = (dispatch, response) => {
  if (response.status === 200) {
    response.blob()
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', EXCEL_TRANSACTIONS_FILENAME);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  }
};

export const updateData = (account, startDate, endDate, specie, entitlementType) => {
  return (dispatch) => {
    retrieve(TOKEN)
    .then(token => fetch(TRANSACTIONS_URL + '?' +
      addParameters(account, startDate, endDate, specie, entitlementType), {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Auth-Token': token
        }
      }))
      .then(response => dispatchUpdate(dispatch, response))
  };
};

const addParameters = (account, startDate, endDate, specie, entitlementType) => {
  return `${TRANSACTIONS_ACCOUNT_PARAM}=${encodeURIComponent(account)}&` + 
  `${ TRANSACTIONS_START_DATE_PARAM }=${ encodeURIComponent(startDate) }&` +
  `${TRANSACTIONS_END_DATE_PARAM}=${encodeURIComponent(endDate)}&` +
  `${ TRANSACTIONS_SPECIE_PARAM }=${ encodeURIComponent(specie) }&` +
  `${TRANSACTIONS_ENTITLEMENT_TYPE_PARAM}=${encodeURIComponent(entitlementType)}`;
};

const dispatchUpdate = (dispatch, response) => {
  if (response.status === 200) {
    response.json()
      .then(data => onUpdateSuccess(dispatch, data));
  } else if (response.status === 401) {
    dispatchLogoutProcess(dispatch);
  } else if (response.status === 500) {
    onUpdateFailure(dispatch);
  }
};

const onUpdateSuccess = (dispatch, data) => {
  dispatch({
    type: TRANSACTIONS_DATA_UPDATE,
    payload: data
  });
};

const onUpdateFailure = (dispatch) => {
  dispatch({
    type: TRANSACTIONS_DATA_UPDATE_FAILURE
  });
};