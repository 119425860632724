import { now, yesterday, firstDayOfYear, oneWeekBefore } from '../.././../utils/DateUtils';
import { ARS } from './constants';

export const INITIAL_STATE = {
  loading: false,
  usePortfolio: true, 
  usePerformance: false,
  useTransactions: true,
  portfolioAgreementDate: now(),
  performanceDateFrom: firstDayOfYear(),
  performanceDateTo: yesterday(),
  transactionsDateFrom: oneWeekBefore(),
  transactionsDateTo: now(),
  portfolioCurrency: ARS,
  performanceCurrency: ARS,
  error: '',
  showModal: false
};