import { AsyncStorage } from 'react-native';

export const store = async (key, value) => {
  await AsyncStorage.setItem(key, value);
};

export const retrieve = async (key) => {
  try {
    const value = await AsyncStorage.getItem(key);
    return value;
  } catch (error) {
    return null;
  }
};

export const remove = async (key) => {
  await AsyncStorage.removeItem(key);
};