import * as colors from '../../../styles/colors';

export const ExternalFormStyle = {
  frameContainerStyle: {
    flex: 1,
    padding: 10,
    height: 'calc(100vh - 60px)'
  },
  containerStyle: {
    flex: 1,
    padding: 10
  },
  messageStyle: {
    marginTop: 25,
    color: colors.SYC_GRAY_CARD_TITLE,
    alignSelf: 'center',
    textAlign: 'center'
  },
  mobileMessageContainerStyle: {
    width: 'calc(100vw - 20px)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 7,
    padding: 7,
  }

};