import * as colors from '../../../../styles/colors';

export const MenuStyle = {
    containerStyle: {
        width: 235,
        height: 'calc(100vh - 70px)',
        marginTop: 20,
        marginStart: 10,
        backgroundColor: colors.SYC_WHITE,
        shadowColor: colors.BLACK,
        shadowOffset: { width: 8, height: 8 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 2,
        zIndex: 5
    },
    mobileContainerStyle: {
        position: 'absolute',
    }
};
