import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { SectionDrawerStyle as style } from './style/SectionDrawer';
import Menu from '../common/menu/Menu';
import LoginForm from '../login/LoginForm';
import PortfolioForm from '../portfolio/PortfolioForm';
import PerformanceForm from '../performance/PerformanceForm';
import TransactionsForm from '../transactions/TransactionsForm';
import PdfReportForm from '../pdf/PdfReportForm';
import PasswordRecoveryForm from '../password_recovery/PasswordRecoveryForm';
import TransfersForm from '../external/TransfersForm';
import ReceiptsForm from '../external/ReceiptsForm';
import WelcomeForm from '../welcome/WelcomeForm';

class SectionDrawer extends Component {

  shouldRenderMenu() {
    if (this.props.token) {
      return <Menu />
    }
  }

  renderContent() {
    if (!this.props.token || this.props.token === '') {
      return <LoginForm />
    } else {
      switch (this.props.selectedMenu) {
        case 1: return <PortfolioForm />
        case 2: return <PerformanceForm />
        case 3: return <TransactionsForm />
        case 4: return <TransfersForm />
        case 5: return <ReceiptsForm />
        case 6: return <PdfReportForm />
        case 7: return <PasswordRecoveryForm />
        default: return <WelcomeForm />
      }
    }
  }

  render() {
    return (
      <View style={style.containerStyle}>
        {this.shouldRenderMenu()}
        {this.renderContent()}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedMenu: state.menu.selectedMenu,
    source: state.external.source,
    externalError: state.external.error,
    token: state.login.token
  };
};

export default connect(mapStateToProps)(SectionDrawer);
