import React, { Component } from 'react';
import { View, FlatList, Text, TouchableWithoutFeedback, Image } from 'react-native';
import { connect } from 'react-redux';
import { showAccounts } from './redux/actions';
import { ActionStyle as style } from './style/Action';
import AccountItem from './AccountItem';
import {
  APPLICATION_RENDERING_MODE_MOBILE as mobile,
  APPLICATION_RENDERING_MODE_SCREEN as screen
} from '../common/application/redux/constants';

class AccountSelectorPane extends Component {

  onShowAction() {
    this.props.showAccounts();
  }

  renderItem(account) {
    return <AccountItem item={account.item} />
  }

  renderList() {
    if (this.props.show) {
      return (
        <View style={style.accountContainerStyle}>
          <FlatList
            data={this.props.accounts}
            renderItem={this.renderItem}
            keyExtractor={(account) => account.accountId}
          />
        </View>
      );
    } else {
      return <View />
    }
  }

  displayText() {
    if (this.props.selectedAccount) {
      return (
        `[${this.props.selectedAccount.accountId}] ${this.props.selectedAccount.denomination}`
      );
    } else {
      return (
        'Cargando...'
      );
    }
  }

  getAccountMenu() {
    if (this.props.renderingMode === screen) {
      return this.getScreenAccountMenu();
    } else if (this.props.renderingMode === mobile) {
      return this.getMobileAccountMenu();
    }
  }

  getScreenAccountMenu() {
    if (this.props.selectedAccount) {
      return (
        <TouchableWithoutFeedback
          onPress={this.onShowAction.bind(this)}
        >
          {this.renderScreenAccountMenu()}
        </TouchableWithoutFeedback>
      );
    } else {
      return this.renderScreenAccountMenu();
    }
  }

  renderScreenAccountMenu() {
    return (
      <View style={style.textContainerStyle}>
        <Text style={style.textStyle}>
          {this.displayText()}
        </Text>
      </View>
    );
  }

  getMobileAccountMenu() {
    if (this.props.selectedAccount) {
      return (
        <TouchableWithoutFeedback
          onPress={this.onShowAction.bind(this)}
        >
          {this.renderMobileAccountMenu()}
        </TouchableWithoutFeedback>
      );
    } else {
      this.renderMobileAccountMenu();
    }
  }

  renderMobileAccountMenu() {
    return (
      <View>
        <Image
          source={require('./assets/tasks.svg')}
          style={style.imageStyle}
        />
      </View>
    );
  }

  render() {
    return (
      <View>
        {this.getAccountMenu()}
        {this.renderList()}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    show: state.account.showAccounts,
    selectedAccount: state.account.selectedAccount,
    accounts: state.accountItems.accounts,
    renderingMode: state.application.renderingMode
  }
};

export default connect(mapStateToProps, { showAccounts })(AccountSelectorPane);
