import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { SmallTextStyle as style } from '../../styles/common';

class SmallText extends Component {
  render() {
    return (
      <View style={this.props.style}>
        <Text style={style.textStyle}>
          {this.props.children}
        </Text>
      </View>
    );
  }
}

export { SmallText };
