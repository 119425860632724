import * as colors from '../colors';

export const SeparatorStyle = {
  contentStyle: {
    flex: 1,
    height: 1,
    width: '100%',
    paddingStart: 5,
    paddingEnd: 5,
    margin: 5,
    borderColor: colors.SYC_GRAY_DISABLED_SWITCH,
    borderBottomWidth: 0,
    borderWidth: 1,
    shadowColor: colors.BLACK,
    shadowRadius: 2,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
  }
};
