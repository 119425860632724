import {
  PERFORMANCE_SWITCH_CHART_TYPE,
  PERFORMANCE_SWITCH_CURRENCY,
  PERFORMANCE_SWITCH_ACCOUNT,
  PERFORMANCE_DATA_UPDATE,
  PERFORMANCE_SWITCH_DATE,
  PERFORMANCE_DATA_UPDATE_FAILURE,
  PERFORMANCE_ACCUMULATED_CHART_DATA,
  PERFORMANCE_PERFORMANCE_CHART_DATA,
  PERFORMANCE_MONTHLY_CHART_DATA
} from "./types";
import { retrieve } from "../../../storage/Storage";
import { TOKEN } from "../../../storage/keys";
import { PERFORMANCE_URL, ACCUMULATED_CHART_URL, MONTHLY_CHART_URL, PERFORMANCE_CHART_URL } from "../../../service/Endpoints";
import {
  PERFORMANCE_ACCOUNT_PARAM,
  PERFORMANCE_START_DATE_PARAM,
  PERFORMANCE_END_DATE_PARAM,
  PERFORMANCE_CURRENCY_PARAM
} from "./constants";
import { dispatchLogoutProcess } from "../../actions/redux/actions";

export const switchChartType = (value) => {
  return {
    type: PERFORMANCE_SWITCH_CHART_TYPE,
    payload: value
  };
};

export const switchCurrency = (value) => {
  return {
    type: PERFORMANCE_SWITCH_CURRENCY,
    payload: value
  }
};

export const invalidateAccount = (newAccount) => {
  return {
    type: PERFORMANCE_SWITCH_ACCOUNT,
    payload: newAccount
  };
};

export const changeSelectedDate = (date, dateType) => {
  return {
    type: PERFORMANCE_SWITCH_DATE + dateType,
    payload: date
  };
};

export const updateData = (account, startDate, endDate, currency) => {
  return (dispatch) => {
    retrieve(TOKEN)
      .then(token => Promise.all(
        fetch(PERFORMANCE_URL + '?' +
          addParameters(account.accountId, startDate, endDate, currency), {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Auth-Token': token
          }
        })
          .then(response => {
            dispatchUpdate(dispatch, response, PERFORMANCE_DATA_UPDATE)
          }),
        fetch(ACCUMULATED_CHART_URL + '?' +
          addParameters(account.accountId, startDate, endDate, currency), {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Auth-Token': token
          }
        })
          .then(response => {
            dispatchUpdate(dispatch, response, PERFORMANCE_ACCUMULATED_CHART_DATA)
          }),
        fetch(MONTHLY_CHART_URL + '?' +
          addParameters(account.accountId, startDate, endDate, currency), {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Auth-Token': token
          }
        })
          .then(response => {
            dispatchUpdate(dispatch, response, PERFORMANCE_MONTHLY_CHART_DATA)
          }),
        fetch(PERFORMANCE_CHART_URL + '?' +
          addParameters(account.accountId, startDate, endDate, currency), {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Auth-Token': token
          }
        })
          .then(response => {
            dispatchUpdate(dispatch, response, PERFORMANCE_PERFORMANCE_CHART_DATA)
          })
      ).catch(reason => { console.log(reason); }))
  };
};

const addParameters = (account, startDate, endDate, currency) => {
  return `${PERFORMANCE_ACCOUNT_PARAM}=${encodeURIComponent(account)}&` +
    `${PERFORMANCE_START_DATE_PARAM}=${encodeURIComponent(startDate)}&` +
    `${PERFORMANCE_END_DATE_PARAM}=${encodeURIComponent(endDate)}&` +
    `${PERFORMANCE_CURRENCY_PARAM}=${encodeURIComponent(currency)}`;
};

const dispatchUpdate = (dispatch, response, updateType) => {
  if (response.status === 200) {
    if (updateType !== PERFORMANCE_DATA_UPDATE) {
      response.blob()
        .then(data => {
          return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function () {
              resolve(reader.result);
            }
          });
        })
        .then(data => onUpdateSuccess(dispatch, data, updateType));
    } else {
      response.json()
        .then((data) => {
          onUpdateSuccess(dispatch, data, updateType);
        });
    }
  } else if (response.status === 401) {
    dispatchLogoutProcess(dispatch);
  } else if (response.status === 500) {
    onUpdateFailure(dispatch);
  }
};

const onUpdateSuccess = (dispatch, data, updateType) => {
  dispatch({
    type: updateType,
    payload: data
  });
};

const onUpdateFailure = (dispatch) => {
  dispatch({
    type: PERFORMANCE_DATA_UPDATE_FAILURE
  });
};