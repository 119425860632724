import React, { Component } from 'react';
import { View } from 'react-native';
import { Column as style } from '../../styles/common';

class Column extends Component {

  render() {
    return (
      <View style={style.centeredColumn}>
        {this.props.children}
      </View>
    );
  }
}

export { Column };
