// Dev server url
//const BASE_URL = 'http://localhost:8080/';
// Prod server url
const BASE_URL = 'https://app.sycinversiones.com/api/';
export const LOGIN_URL = BASE_URL + 'login/';
export const KEEP_ALIVE_URL = BASE_URL + 'keepalive/';
export const ACCOUNT_FETCH_URL = BASE_URL + 'account/';
export const LOGOUT_URL = BASE_URL + 'logout/';
export const POSITIONS_UPDATE_URL = BASE_URL + 'positions/'
export const PERFORMANCE_URL = BASE_URL + 'performance/';
export const TRANSACTIONS_URL = BASE_URL + 'transactions/';
export const PDF_REPORT_URL = BASE_URL + '/pdf';
export const CURRENCY_CHART_URL = BASE_URL + 'currency/chart';
export const COMPOSITION_CHART_URL = BASE_URL + 'composition/chart';
export const ACCUMULATED_CHART_URL = BASE_URL + 'performance/accumulatedChart';
export const MONTHLY_CHART_URL = BASE_URL + 'performance/monthlyChart';
export const PERFORMANCE_CHART_URL = BASE_URL + 'performance/performanceChart';
export const PASSWORD_RECOVERY_URL = BASE_URL + 'changePassword/';
export const TRANSFERS_URL = BASE_URL + 'transfers/';
export const RECEIPTS_URL = BASE_URL + 'receipts/';
export const EXCEL_TRANSACTIONS_URL = BASE_URL + 'excel/';
