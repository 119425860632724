import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { CenteredErrorMessageStyle as style } from '../../styles/common';
import { 
  APPLICATION_RENDERING_MODE_MOBILE as mobile,
  APPLICATION_RENDERING_MODE_SCREEN as screen
} from '../../features/common/application/redux/constants';

class CenteredErrorMessage extends Component {

  getResponsiveStyle() {
    if (this.props.renderingMode === mobile) {
      return style.mobileNoAccountMessageStyle;
    } else if (this.props.renderingMode === screen) {
      return style.noAccountMessageStyle;
    }
  }

  render () {
    return (
      <View style={style.containerStyle}>
        <Text style={this.getResponsiveStyle()}>
          {this.props.message}
        </Text>
      </View>
    );
  }
}

export { CenteredErrorMessage };