import { EXTERNAL_AUTH_SUCCESS, EXTERNAL_AUTH_FAILURE } from "./types";
import { EXTERNAL_UNAUTHORIZED } from "./constants";
import { INITIAL_STATE } from "./initialState";

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXTERNAL_AUTH_SUCCESS:
      return { ...state, loading: false, source: action.payload.url };
    case EXTERNAL_AUTH_FAILURE:
      return { ...state, loading: false, error: EXTERNAL_UNAUTHORIZED};
    default:
      return state;
  }
};