import * as colors from '../colors';

export const SuccessTextStyle = {
  containerStyle: {
    padding: 15,
    paddingTop: 30,
    alignSelf: 'stretch',
    backgroundColor: colors.SYC_WHITE,
  },
  textStyle: {
    fontSize: 20,
    alignSelf: 'center',
    color: colors.SYC_GREEN
  }
};