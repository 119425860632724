import { CHART_TYPE_ACTIVE } from "./constants";
import { format } from "../../../utils/DateUtils";

export const INITIAL_STATE = {
  currency: 'ARS',
  chartType: CHART_TYPE_ACTIVE,
  compositionChartData: null,
  currencyChartData: null,
  selectedChart: '',
  loading: true,
  data: null,
  error: '',
  selectedAccount: null,
  selectedDate: format(new Date())
}

function yesterday() {
  var yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
}