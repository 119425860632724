import { combineReducers } from 'redux';
import HeaderReducer from '../features/common/header/redux/HeaderReducer';
import MenuReducer from '../features/common/menu/redux/MenuReducer';
import MenuItemReducer from '../features/common/menu/redux/MenuItemReducer';
import LoginReducer from '../features/login/redux/LoginReducer';
import ApplicationReducer from '../features/common/application/redux/ApplicationReducer';
import UserReducer from '../features/actions/redux/UserReducer';
import UserActionReducer from '../features/actions/redux/UserActionReducer';
import AccountActionReducer from '../features/actions/redux/AccountActionReducer';
import AccountReducer from '../features/actions/redux/AccountReducer';
import PdfReportActionReducer from '../features/actions/redux/PdfReportActionReducer';
import PortfolioReducer from '../features/portfolio/redux/PortfolioReducer';
import PerformanceReducer from '../features/performance/redux/PerformanceReducer';
import TransactionsReducer from '../features/transactions/redux/TransactionsReducer';
import PdfReducer from '../features/pdf/redux/PdfReducer';
import RecoveryReducer from '../features/password_recovery/redux/RecoveryReducer';
import ExternalReducer from '../features/external/redux/ExternalReducer';
import { ACTIONS_USER_LOGOUT } from '../features/actions/redux/types';
import { SESSION_EXPIRED } from '../features/login/redux/types';

const rootReducer = (state, action) => {
    if (action.type === ACTIONS_USER_LOGOUT || action.type === SESSION_EXPIRED) {
        state = undefined
    }
    return appReducer(state, action)
};

const appReducer = combineReducers({
    header: HeaderReducer,
    menuItems: MenuReducer,
    menu: MenuItemReducer,
    login: LoginReducer,
    application: ApplicationReducer,
    user: UserReducer,
    actionItems: UserActionReducer,
    pdfReport: PdfReportActionReducer,
    accountItems: AccountActionReducer,
    account: AccountReducer,
    portfolio: PortfolioReducer,
    performance: PerformanceReducer,
    transactions: TransactionsReducer,
    pdf: PdfReducer,
    recovery: RecoveryReducer,
    external: ExternalReducer
});

export default rootReducer;