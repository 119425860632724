export const CHART_TYPE_PERFORMANCE = "PERFORMANCE";
export const CHART_TYPE_ACCUMULATED = "ACUMULADO";
export const CHART_TYPE_MONTHLY = "MENSUAL";
export const ARS = "ARS";
export const USD = "USD";
export const PERFORMANCE_ACCOUNT_PARAM = 'cuenta';
export const PERFORMANCE_START_DATE_PARAM = 'fechaDesde';
export const PERFORMANCE_END_DATE_PARAM = 'fechaHasta';
export const PERFORMANCE_CURRENCY_PARAM = 'moneda';
export const PERFORMANCE_START_DATE = 'start_date';
export const PERFORMANCE_END_DATE = 'end_date';
