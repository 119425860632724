import * as colors from '../../../../styles/colors';
export const MenuItemStyle = {
    containerStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.SYC_MENU_ITEM_SELECTED,
        flex: 1,
        height: 45,
        marginTop: 15,
        marginBottom: 15
    },
    containerNoSelectedStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.SYC_WHITE,
        flex: 1,
        height: 45,
        marginTop: 15,
        marginBottom: 15
    },
    selectorStyle: {
        width: 5,
        height: 45,
        marginEnd: 5,
        backgroundColor: colors.SYC_GREEN
    },
    noSelectorStyle: {
        marginStart: 10,
        backgroundColor: colors.SYC_WHITE
    },
    imageStyle: {
        width: 25,
        height: 25,
        marginTop: 3,
        marginBottom: 3,
        marginStart: 15,
        marginEnd: 15
    },
    textStyle: {
        fontSize: 18,
        marginStart: 20,
        color: colors.SYC_MENU_ITEM_TEXT,
        flexDirection: 'column',
        alignItems: 'center'
    }
};
