import { retrieve } from "../../../storage/Storage";
import { TOKEN } from "../../../storage/keys";
import { PDF_REPORT_URL } from "../../../service/Endpoints";
import {
  PDF_ACCOUNT_PARAM,
  PDF_PORTFOLIO_AGREEMENT_DATE_PARAM,
  PDF_PORTFOLIO_CURRENCY_PARAM,
  PDF_PERFORMANCE_DATE_FROM_PARAM,
  PDF_PERFORMANCE_DATE_TO_PARAM,
  PDF_PERFORMANCE_CURRENCY_PARAM,
  PDF_PORTFOLIO_PARAM,
  PDF_PERFORMANCE_PARAM,
  PDF_TRANSACTIONS_PARAM,
  PDF_ERROR_MESSAGE,
  PDF_REPORT_FILENAME,
  PDF_TRANSACTIONS_DATE_TO_PARAM,
  PDF_TRANSACTIONS_DATE_FROM_PARAM,
  PDF_ERROR_NO_DATA_MESSAGE,
  PDF_DOWNLOAD_NO_OPTIONS_SELECTED_MESSAGE,
  PDF_ERROR_UNEXPECTED_MESSAGE
} from "./constants";
import {
  TOGGLE_REPORT,
  CHANGE_DATE,
  CHANGE_CURRENCY,
  PDF_DOWNLOAD_STATE,
  PDF_DOWNLOAD_ERROR,
  PDF_DOWNLOAD_NO_OPTIONS_SELECTED,
  CLOSE_MODAL,
  OPEN_MODAL
} from "./types";
import { format } from '../.././../utils/DateUtils';
import { dispatchLogoutProcess } from "../../actions/redux/actions";

export const toggleReport = (report) => {
  return {
    type: TOGGLE_REPORT + report
  };
};

export const closeNoDataModal = () => {
  return {
    type: CLOSE_MODAL
  };
};

export const changeReportDate = (date, which) => {
  return {
    type: CHANGE_DATE + which,
    payload: date
  };
};

export const changeReportCurrency = (currency, which) => {
  return {
    type: CHANGE_CURRENCY + which,
    payload: currency
  };
};

export const downloadPdf = (
  account,
  usePortfolio,
  portfolioAgreementDate,
  portfolioCurrency,
  usePerformance,
  performanceDateFrom,
  performanceDateTo,
  performanceCurrency,
  useTransactions,
  transactionsDateFrom,
  transactionsDateTo
) => {
  return (dispatch) => {
    const atleastOneToggleSelected = usePortfolio || usePerformance || useTransactions;
    if (atleastOneToggleSelected) {
      loading(dispatch, true);
      retrieve(TOKEN)
        .then(token => fetch(PDF_REPORT_URL + '?' +
          addParameters(account,
            format(portfolioAgreementDate),
            portfolioCurrency,
            format(performanceDateFrom),
            format(performanceDateTo),
            performanceCurrency,
            format(transactionsDateFrom),
            format(transactionsDateTo)) +
          addReports(usePortfolio,
            usePerformance,
            useTransactions), {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Auth-Token': token
          }
        }))
        .then(response => dispatchDownload(dispatch, response))
        .then(() => loading(dispatch, false));

    } else {
      dispatchNoOptionsSelected(dispatch, PDF_DOWNLOAD_NO_OPTIONS_SELECTED_MESSAGE)
    }
  };
};

const dispatchDownload = (dispatch, response) => {
  if (response.status === 200) {
    response.blob()
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', PDF_REPORT_FILENAME);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  } else if (response.status === 400) {
    dispatch({
      type: PDF_DOWNLOAD_ERROR,
      payload: PDF_ERROR_MESSAGE
    });
  } else if (response.status === 401) {
    dispatchLogoutProcess(dispatch);
  } else if (response.status === 204) {
    dispatch({
      type: OPEN_MODAL,
      payload: PDF_ERROR_NO_DATA_MESSAGE
    })
  }
  else if (response.status === 500) {
    dispatch({
      type: OPEN_MODAL,
      payload: PDF_ERROR_UNEXPECTED_MESSAGE
    })
  }
};

const loading = (dispatch, loading) => {
  dispatch({
    type: PDF_DOWNLOAD_STATE,
    payload: loading
  });
};

const dispatchNoOptionsSelected = (dispatch, message) => {
  dispatch({
    type: PDF_DOWNLOAD_NO_OPTIONS_SELECTED,
    payload: message
  });
}


const addParameters = (
  account,
  portfolioAgreementDate,
  portfolioCurrency,
  performanceDateFrom,
  performanceDateTo,
  performanceCurrency,
  transactionsDateFrom,
  transactionsDateTo) => {
  var body = [];
  body.push(
    `${PDF_ACCOUNT_PARAM}=${encodeURIComponent(account)}`
  );
  body.push(
    `${PDF_PORTFOLIO_AGREEMENT_DATE_PARAM}=${encodeURIComponent(portfolioAgreementDate)}`
  );
  body.push(
    `${PDF_PORTFOLIO_CURRENCY_PARAM}=${encodeURIComponent(portfolioCurrency)}`
  );
  body.push(
    `${PDF_PERFORMANCE_DATE_FROM_PARAM}=${encodeURIComponent(performanceDateFrom)}`
  );
  body.push(
    `${PDF_PERFORMANCE_DATE_TO_PARAM}=${encodeURIComponent(performanceDateTo)}`
  );
  body.push(
    `${PDF_PERFORMANCE_CURRENCY_PARAM}=${encodeURIComponent(performanceCurrency)}`
  );
  body.push(
    `${PDF_TRANSACTIONS_DATE_FROM_PARAM}=${encodeURIComponent(transactionsDateFrom)}`
  );
  body.push(
    `${PDF_TRANSACTIONS_DATE_TO_PARAM}=${encodeURIComponent(transactionsDateTo)}`
  );
  return body.join("&");
};

const addReports = (
  usePortfolio,
  usePerformance,
  useTransactions
) => {
  var body = [];
  if (usePortfolio) {
    body.push(PDF_PORTFOLIO_PARAM);
  }
  if (usePerformance) {
    body.push(PDF_PERFORMANCE_PARAM);
  }
  if (useTransactions) {
    body.push(PDF_TRANSACTIONS_PARAM);
  }
  return `&reports=${body.join(",")}`;
};