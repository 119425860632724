import { LOGIN_LOGIN_FIELDS } from "./constants";

export const INITIAL_STATE = {
  email: '',
  password: '',
  token: '',
  loading: false,
  error: '',
  fields: LOGIN_LOGIN_FIELDS,
  loadingToken: false
};