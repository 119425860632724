import { INITIAL_STATE } from "./initialState";
import { 
  PORTFOLIO_SWITCH_CURRENCY,
  PORTFOLIO_DATA_UPDATE,
  PORTFOLIO_NO_ACCOUNT_SELECTED,
  PORTFOLIO_SWITCH_ACCOUNT,
  PORTFOLIO_SWITCH_CHART_TYPE,
  PORTFOLIO_CHANGE_SELECTED_DATE,
  PORTFOLIO_CURRENCY_CHART_DATA,
  PORTFOLIO_COMPOSITION_CHART_DATA,
  PORTFOLIO_UPDATE_ERROR
} from './types';

export default (state = INITIAL_STATE,  action) => {
  switch(action.type) {
    case PORTFOLIO_SWITCH_CURRENCY:
      return { ...state, currency: action.payload };
    case PORTFOLIO_SWITCH_CHART_TYPE:
      return { ...state, chartType: action.payload };
    case PORTFOLIO_DATA_UPDATE:
      return { ...state, data: action.payload, loading: false, error: '' };
    case PORTFOLIO_NO_ACCOUNT_SELECTED:
      return { ...state, data: null, loading: false };
    case PORTFOLIO_SWITCH_ACCOUNT: 
      return { ...state, data: null, loading: true, selectedAccount: action.payload };
    case PORTFOLIO_CHANGE_SELECTED_DATE:
      return { ...state, data: null, loading: true, selectedDate: action.payload };
    case PORTFOLIO_COMPOSITION_CHART_DATA:
      return { ...state, compositionChartData: action.payload };
    case PORTFOLIO_CURRENCY_CHART_DATA:
      return { ...state, currencyChartData: action.payload };
    case PORTFOLIO_UPDATE_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}