import * as colors from '../../../../styles/colors';

export const HeaderStyle = {
    imageStyle: {
        resizeMode: 'contain',
        width: 140,
        height: 70,
        padding: 5,
        marginStart: 10
    },
    mobileImageStyle: {
        resizeMode: 'cover',
        width: 30,
        height: 30,
        marginStart: 10
    },
    textStyle: {
        fontSize: 20,
        alignSelf: 'center',
        marginStart: -50,
        color: colors.OLD_WHITE,
        textShadowColor: colors.BLACK,
        textShadowOffset: { width: 3, height: 3 },
        textShadowRadius: 5
    },
    mobileTextStyle: {
        fontSize: 18,
        alignSelf: 'center',
        color: colors.OLD_WHITE,
        textShadowColor: colors.BLACK,
        textShadowOffset: { width: 3, height: 3 },
        textShadowRadius: 5,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    viewStyle: {
        backgroundColor: colors.SYC_DARK_BLUE_GRAY,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 50,
        shadowColor: colors.BLACK,
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 2,
        position: 'relative'
    },
    headerLogoStyle: {
        flex: 10,
        minWidth: 'max-content',

    },
    titleStyle: {
        paddingLeft:'5%',
        paddingRight:'15%',
        flexGrow: 60,
        maxWidth:'60%',
        flexDirection:'row',
        justifyContent:'flex-end'
    },
    actionsStyle: {
        marginEnd: 100,
        flex: 30
    }
};