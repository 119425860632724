import { 
  PORTFOLIO_SWITCH_CURRENCY,
  PORTFOLIO_SWITCH_CHART_TYPE,
  PORTFOLIO_DATA_UPDATE,
  PORTFOLIO_NO_ACCOUNT_SELECTED,
  PORTFOLIO_SWITCH_ACCOUNT,
  PORTFOLIO_CHANGE_SELECTED_DATE,
  PORTFOLIO_COMPOSITION_CHART_DATA,
  PORTFOLIO_CURRENCY_CHART_DATA,
  PORTFOLIO_UPDATE_ERROR
} from './types';
import { retrieve } from '../../../storage/Storage';
import { TOKEN } from '../../../storage/keys';
import { 
  POSITIONS_UPDATE_URL,
  CURRENCY_CHART_URL,
  COMPOSITION_CHART_URL
} from '../../../service/Endpoints';
import { 
  PORTFOLIO_ACCOUNT_PARAM, 
  PORTFOLIO_DATE_PARAM,
  PORTFOLIO_ERROR_MESSAGE} from './constants';
import { dispatchLogoutProcess } from '../../actions/redux/actions';
import { format } from '../../../utils/DateUtils';

export const switchCurrency = (value) => {
  return {
    type: PORTFOLIO_SWITCH_CURRENCY,
    payload: value
  };
};

export const switchChartType = (value) => {
  return {
    type: PORTFOLIO_SWITCH_CHART_TYPE,
    payload: value
  };
};

export const invalidateAccount = (newAccount) => {
  return {
    type: PORTFOLIO_SWITCH_ACCOUNT,
    payload: newAccount
  };
};

export const noAccountSelected = () => {
  return {
    type: PORTFOLIO_NO_ACCOUNT_SELECTED
  };
};

export const changeSelectedDate = (date) => {
  return {
    type: PORTFOLIO_CHANGE_SELECTED_DATE,
    payload: format(date)
  };
};

export const updateData = (account, date) => {
    return (dispatch) => {
      retrieve(TOKEN)
      .then(token => Promise.all(
        fetch(POSITIONS_UPDATE_URL, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Auth-Token': token
          },
          body: formBody(account.accountId, date)
        }).then(response => {
          dispatchUpdate(dispatch, response, PORTFOLIO_DATA_UPDATE)
        }),
        fetch(CURRENCY_CHART_URL + '?' + formBody(account.accountId, date), {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Auth-Token': token
          },
        })
        .then(response => { 
          dispatchUpdate(dispatch, response, PORTFOLIO_CURRENCY_CHART_DATA)
        }),
        fetch(COMPOSITION_CHART_URL + '?' + formBody(account.accountId, date), {
          method: 'GET', 
          mode: 'cors', 
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Auth-Token': token
          }
        })
        .then(response => {
          dispatchUpdate(dispatch, response, PORTFOLIO_COMPOSITION_CHART_DATA)
        })
      )
      .catch(error => console.log(error)))};
};

const formBody = (account, date) => {
  return `${PORTFOLIO_ACCOUNT_PARAM}=${encodeURIComponent(account)}&` +
  `${PORTFOLIO_DATE_PARAM}=${encodeURIComponent(date)}`;
};

const dispatchUpdate = (dispatch, response, updateType) => {
  if (response.status === 200) {
    if (updateType !== PORTFOLIO_DATA_UPDATE) {
      response.blob()
      .then(data => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsDataURL(data); 
          reader.onloadend = function() {
            resolve(reader.result);
          }
        });
      })
      .then(data => onUpdateSuccess(dispatch, data, updateType));
    } else {
      response.json()
      .then(data => onUpdateSuccess(dispatch, data, updateType));
    }
  } else if (response.status === 401) {
    dispatchLogoutProcess(dispatch);
  } else if (response.status === 500) {
    onUpdateFailed(dispatch, PORTFOLIO_ERROR_MESSAGE)
  }
};

const onUpdateSuccess = (dispatch, data, updateType) => {
  dispatch({
    type: updateType,
    payload: data
  });
};

const onUpdateFailed = (dispatch, message) => {
  dispatch({
    type: PORTFOLIO_UPDATE_ERROR,
    payload: message
  });
};
