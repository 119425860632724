import {
  EMAIL_CHANGED,
  ID_CHANGED,
  PASSWORD_RECOVERY_LOADING,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_CANCEL,
  PASSWORD_RECOVERY_ERROR,
  PASSWORD_RECOVERY_EMPTY_FIELDS
} from "./types";
import { PASSWORD_RECOVERY_URL } from "../../../service/Endpoints";
import {
  PASSWORD_RECOVERY_EMAIL_PARAM,
  PASSWORD_RECOVERY_ID_PARAM,
  PASSWORD_RECOVERY_EMPTY_FIELDS_MESSAGE,
  PASSWORD_RECOVERY_SUCCESS_MESSAGE,
  PASSWORD_RECOVERY_FAILED,
  PASSWORD_RECOVERY_UNAUTHORIZED_MESSAGE
} from "./constants";

export const emailChanged = (email) => {
  return {
    type: EMAIL_CHANGED,
    payload: email
  };
};

export const idChanged = (id) => {
  return {
    type: ID_CHANGED,
    payload: id
  };
};

export const isLoading = (status) => {
  return {
    type: PASSWORD_RECOVERY_LOADING,
    payload: status
  };
};

export const cancel = () => {
  return {
    type: PASSWORD_RECOVERY_CANCEL
  };
};

export const requestPasswordRecovery = (email, id) => {
  return (dispatch) => {
    const fieldsHaveValues = email !== "" && id !== "";
    if (fieldsHaveValues) {
      fetch(PASSWORD_RECOVERY_URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody(email, id)
      })
        .then(response => dispatchResponse(dispatch, response))
        .catch(() => dispatchError(dispatch))
    } else {
      dispatchPasswordRecoveryEmptyFields(dispatch, PASSWORD_RECOVERY_EMPTY_FIELDS_MESSAGE);
    }
  };
};

const formBody = (email, id) => {
  return `${PASSWORD_RECOVERY_EMAIL_PARAM}=${encodeURIComponent(email)}&` +
    `${PASSWORD_RECOVERY_ID_PARAM}=${encodeURIComponent(id)}`;
};

const dispatchResponse = (dispatch, response) => {
  if (response.status === 200) {
    onRecoverySuccess(dispatch, PASSWORD_RECOVERY_SUCCESS_MESSAGE);
  } else {
    onRecoveryFailed(dispatch, PASSWORD_RECOVERY_UNAUTHORIZED_MESSAGE);
  }
};

const dispatchError = (dispatch) => {
  dispatch({
    type: PASSWORD_RECOVERY_FAILED
  });
};

const onRecoverySuccess = (dispatch, message) => {
  dispatch({
    type: PASSWORD_RECOVERY_SUCCESS,
    payload: message
  });
};

const dispatchPasswordRecoveryEmptyFields = (dispatch, message) => {
  dispatch({
    type: PASSWORD_RECOVERY_EMPTY_FIELDS,
    payload: message
  });
};

const onRecoveryFailed = (dispatch, message) => {
  dispatch({
    type: PASSWORD_RECOVERY_ERROR,
    payload: message
  });
};