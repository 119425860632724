import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image
} from 'react-native';
import { connect } from 'react-redux';
import { ActionItemStyle as style } from './style/ActionItem';
import { selectAccount } from './redux/actions';
import { ellipsize } from '../../utils/StringUtils';
import { ACCOUNT_DENOMINATION_MAX_LENGTH } from './redux/constants';

class AccountItem extends Component {

  getItemStyle() {
    if (this.props.selected.accountId === this.props.item.accountId) {
      return style.containerStyle;
    } else {
      return style.containerNotSelectedStyle;
    }
  }

  shouldShowSelector() {
    if (this.props.selected.accountId === this.props.item.accountId) {
      return style.selectorStyle;
    } else {
      return style.noSelectorStyle;
    }
  }

  render() {
    const account = this.props.item;

    return (
      <TouchableOpacity
        onPress={() => this.props.selectAccount(account)}
      >
        <View style={this.getItemStyle()}>
          <Image
            source={require('./assets/selector_color.png')}
            style={this.shouldShowSelector()}
          />
          <Text style={style.textStyle} numberOfLines={1}>
            [{account.accountId}] {ellipsize(account.denomination, ACCOUNT_DENOMINATION_MAX_LENGTH)}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = state => {
  console.log(state.account.selectedAccount)
  return {
    selected: state.account.selectedAccount
  };
};

export default connect(mapStateToProps, { selectAccount })(AccountItem);
