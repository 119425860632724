export const SYC_DARK_BLUE_GRAY = '#3A464B';
export const SYC_HEADER_TEXT = '#CDCCCC';
export const SYC_MAIN_BACKGROUND = '#ebebeb';
export const SYC_MENU_ITEM_TEXT = '#b5b5b6';
export const SYC_MENU_ITEM_SELECTED = '#f2f2f2';
export const SYC_WHITE = '#fcfcfc';
export const SYC_GREEN = '#95D600';
export const SYC_GRAY_DISABLED_SWITCH = '#ABAAAA';
export const SYC_GRAY_CARD_TITLE = '#58525A';
export const SYC_GRAY_CARD_TEXT = '#838383';
export const SYC_YELLOW = '#EFEA55'
export const SYC_RED = '#e92e2f';
export const SYC_LIGHT_GRAY = '#e5e5e5';
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const OLD_WHITE = '#FAFAFA';
export const RED = '#FF0000';
export const TRANSPARENT = 'transparent';
export const CHART_CASH = SYC_GRAY_CARD_TEXT;
export const CHART_FIXED = SYC_GREEN;
export const CHART_VARIABLE = SYC_GRAY_CARD_TITLE;
export const CHART_OTHER = SYC_YELLOW;
export const CHART_PESOS = SYC_GRAY_CARD_TEXT;
export const CHART_DIVISAS = SYC_GREEN;