import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { SuccessTextStyle as style } from '../../styles/common';

class SuccessText extends Component {
  render () {
    return (
      <View style={style.containerStyle}>
        <Text style={style.textStyle}>{this.props.children}</Text>
      </View>
    );
  }
}

export { SuccessText };