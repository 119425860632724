import * as colors from '../../../styles/colors';

export const PasswordRecoveryFieldsStyle = {
  cancelButtonStyle: {
    backgroundColor: 'transparent',
    marginTop: 25,
    alignSelf: 'center'
  },
  cancelTextStyle: {
    color: colors.SYC_GRAY_CARD_TITLE
  }
};