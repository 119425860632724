import * as colors from '../../../styles/colors';

export const LoginFieldsStyle = {
  passwordRecoveryButtonStyle: {
    backgroundColor: 'transparent',
    marginTop: 25,
    alignSelf: 'center'
  },
  passwordRecoveryTextStyle: {
    color: colors.SYC_GRAY_CARD_TITLE
  },
  buttonStyle: {
    flex: 1,
    backgroundColor: colors.SYC_GREEN,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: colors.SYC_GREEN,
    elevation: 2,
    marginLeft: 5,
    marginRight: 5
  }
};
