import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { View, Text, Image } from 'react-native';
import { DateSelectorStyle as styles } from '../../styles/common';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import { format, now } from '../../utils/DateUtils';
import {
  APPLICATION_RENDERING_MODE_MOBILE as mobile,
  APPLICATION_RENDERING_MODE_SCREEN as screen
} from '../../features/common/application/redux/constants';
registerLocale('es', es);
setDefaultLocale('es');

const PopperContainer = ({ children }) => (
  ReactDOM.createPortal(
    children,
    document.getElementById('portal'),
  )
);

class DateSelector extends Component {

  state = {
    startDate: this.props.startDate || new Date(),
    endDate: this.props.endDate || new Date()
  }

  onChange(date) {
    this.props.onChange(date);
    this.setState({ startDate: date, endDate: date });
  }

  onChangeStart(date) {
    this.props.onChangeStart(date);
    this.setState({ startDate: date });
  }

  onChangeEnd(date) {
    this.props.onChangeEnd(date);
    this.setState({ endDate: date });
  }

  getResponsiveStyle() {
    if (this.props.renderingMode === mobile) {
      return styles.mobileMainContainerStyle;
    } else if (this.props.renderingMode === screen) {
      return styles.mainContainerStyle;
    }
  }

  renderPickers() {
    if (this.props.rangePick) {
      return (
        <View style={this.getResponsiveStyle()}>
          <View style={styles.containerStyle}>
            <Text style={styles.labelStyle}>
              {this.props.labelStart}
            </Text>
            <View style={styles.boxStyle}>
              <Text style={styles.textStyle}>
                {format(this.state.startDate)}
              </Text>
              <DatePicker
                customInput={
                  <Image
                    style={styles.imageStyle}
                    source={require('../../assets/icons/Calendario.svg')}
                  />}
                maxDate={this.props.maxDate}
                selected={this.state.startDate}
                selectsStart
                dateFormat="dd/MM/yyyy"
                highlightDates={[this.state.startDate, this.state.endDate]}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.onChangeStart.bind(this)}
                popperContainer={PopperContainer}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true 
                  },
                }}
              />
            </View>
          </View>

          <View style={styles.containerStyle}>
            <Text style={styles.labelStyle}>
              {this.props.labelEnd}
            </Text>
            <View style={styles.boxStyle}>
              <Text style={styles.textStyle}>
                {format(this.state.endDate)}
              </Text>
              <DatePicker
                customInput={
                  <Image
                    style={styles.imageStyle}
                    source={require('../../assets/icons/Calendario.svg')}
                  />}
                maxDate={this.props.maxDate}
                selected={this.props.endDate}
                selectsEnd
                dateFormat="dd/MM/yyyy"
                highlightDates={[this.state.startDate, this.state.endDate]}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.onChangeEnd.bind(this)}
                minDate={this.state.startDate}
                popperContainer={PopperContainer}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true
                  }
                }}
              />
            </View>
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.containerStyle}>
          <Text style={styles.labelStyle}>
            {this.props.label}
          </Text>
          <View style={styles.boxStyle}>
            <Text style={styles.textStyle}>
              {format(this.state.startDate)}
            </Text>
            <DatePicker
              customInput={
                <Image
                  style={styles.imageStyle}
                  source={require('../../assets/icons/Calendario.svg')}
                />}
              maxDate={now()}
              selected={this.props.startDate || this.state.startDate}
              highlightDates={[this.state.startDate, this.state.endDate]}
              dateFormat="dd/MM/yyyy"
              onChange={this.onChange.bind(this)}
              popperContainer={PopperContainer}
              popperModifiers={{
                preventOverflow: {
                  enabled: true 
                }
              }}
            />
          </View>
        </View>
      );
    }
  }

  render() {
    return (
      this.renderPickers()
    );
  }
}

export { DateSelector };
