import React, { Component } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { ButtonStyle as style } from '../../styles/common';

class Button extends Component {
    render(){
        return (
            <TouchableOpacity 
                disabled={this.props.disabled} 
                onPress={this.props.onPress} 
                style={this.props.style || style.buttonStyle}
            >
                <Text style={this.props.textStyle || style.textStyle}>
                    {this.props.children}
                </Text>
            </TouchableOpacity>
        );
    }
}

export { Button };
