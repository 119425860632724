export const SpinnerStyle = {
  containerStyle: {
    flex: 1,
    padding: 15,
    paddingTop: 30,
    alignSelf: 'stretch'
  },
  spinnerStyle: {
    alignSelf: 'center',
    justifyContent: 'center'
  }
}