import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { Card, CardSection, Input, Button, ErrorText, Spinner } from '../../components/common';
import { CardStyle } from '../../styles/common/Card';
import { emailChanged, passwordChanged, isLoading, loginUser, recoverPassword } from './redux/actions';
import { APPLICATION_RENDERING_MODE_MOBILE, APPLICATION_RENDERING_MODE_SCREEN, ENTER_KEY_CODE } from '../common/application/redux/constants';
import { LoginFieldsStyle as style } from './style/LoginFields';

class LoginFields extends Component {

  constructor(props) {
    super(props);
    this.onLoginPress = this.onLoginPress.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyPress);
  }

  handleKeyPress(event) {
    if (event.keyCode === ENTER_KEY_CODE) {
      this.onLoginPress();
    }
  }

  onEmailChange(email) {
    this.props.emailChanged(email);
  }

  onPasswordChange(password) {
    this.props.passwordChanged(password);
  }

  onLoginPress() {
    const { email, password } = this.props;

    this.props.isLoading(true);
    this.props.loginUser({ email, password });
  }

  onRecoverPasswordPress() {
    this.props.recoverPassword();
  }

  getCardStyle() {
    if (this.props.renderingMode === APPLICATION_RENDERING_MODE_MOBILE) {
      return CardStyle.mobileContainerStyle;
    } else if (this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN) {
      return CardStyle.containerStyle;
    }
  }

  renderLoading() {
    if (this.props.loading) {
      return (
        <CardSection>
          <Spinner />
        </CardSection>
      );
    }
  }

  renderError() {
    if (this.props.error) {
      return (
        <ErrorText>{this.props.error}</ErrorText>
      );
    }
  }

  render() {
    return (
      <Card style={this.getCardStyle()}>
        <CardSection>
          <Input
            placeholder="Usuario"
            labelImage={require('./assets/Usuario.svg')}
            onChangeText={this.onEmailChange.bind(this)}
            style="width:100%"
            value={this.props.email}
          />
        </CardSection>
        <CardSection>
          <Input
            placeholder="Contraseña"
            labelImage={require('./assets/Login-Candado.svg')}
            onChangeText={this.onPasswordChange.bind(this)}
            style="width:100%"
            value={this.props.password}
            secureTextEntry
          />
        </CardSection>

        {this.renderLoading()}

        {this.renderError()}

        <CardSection>
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <Button
              style={style.buttonStyle}
              onPress={this.onLoginPress.bind(this)}
            >
              INGRESAR
            </Button>
            <Button
              style={style.passwordRecoveryButtonStyle}
              textStyle={style.passwordRecoveryTextStyle}
              onPress={this.onRecoverPasswordPress.bind(this)}
            >
              RECUPERAR CONTRASEÑA
            </Button>
          </View>
        </CardSection>
      </Card>
    );
  }

}

const mapStateToProps = state => {
  return {
    email: state.login.email,
    password: state.login.password,
    loading: state.login.loading,
    error: state.login.error,
    renderingMode: state.application.renderingMode
  };
};

export default connect(mapStateToProps, { emailChanged, passwordChanged, isLoading, loginUser, recoverPassword })(LoginFields);
