import React, { Component } from 'react';
import { TextInput, View, Text, Image } from 'react-native';
import { InputStyle as style } from '../../styles/common';

class Input extends Component {
    
    renderLabel() {
        if (this.props.label) {
            return <Text style={style.labelStyle}>{this.props.label}</Text>;
        }
    }

    renderLabelImage() {
        if (this.props.labelImage) {
            return <Image 
                      source={this.props.labelImage} 
                      style={style.labelImageStyle} 
                    />
        }
    }

    render() {
        const { value, onChangeText, placeholder, secureTextEntry } = this.props;

        return(
            <View style={style.containerStyle}>
                {this.renderLabelImage()}
                {this.renderLabel()}
                <TextInput 
                    secureTextEntry={secureTextEntry}
                    placeholder={placeholder}
                    autoCorrect={false}
                    autoCapitalize="none"
                    style={style.inputStyle}
                    value={value}
                    onChangeText={onChangeText} />
            </View>
        );
    }
}

export { Input };
