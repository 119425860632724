import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { ExternalFormStyle as style } from './style/ExternalForm';
import { requestAccess } from './redux/actions';
import { LoadingIndicator } from '../../components/common/LoadingIndicator';
import { TRANSFERS_URL } from '../../service/Endpoints';
import {
  APPLICATION_RENDERING_MODE_MOBILE,
  APPLICATION_RENDERING_MODE_SCREEN
} from '../../features/common/application/redux/constants';

class TransfersForm extends Component {

  componentWillMount() {
    this.props.requestAccess(TRANSFERS_URL);
  }

  loadIFrame = () => {
    this.refs.iframe.src = this.props.source;
  }

  getMainView() {
    if (this.props.loading) {
      return (
        <LoadingIndicator
          message="Esta operación puede tardar varios segundos"
        />
      );
    } else {
      if (this.props.source && !this.props.error) {
        setTimeout(this.loadIFrame);
        return (
          <View style={style.frameContainerStyle}>
            <iframe ref="iframe" title="web" style={{ height: '100%', width: '100%', border: 0, seamless: true }} ></iframe>
          </View>
        );
      } else {
        return (
          <View style={style.containerStyle}>
            <Text style={style.messageStyle}>
              {this.props.error}
            </Text>
          </View>
        );
      }
    }
  }

  getMobileMessage() {
    return (
      <View style={style.mobileMessageContainerStyle}>
        <Text style={style.messageStyle}>
          Por el momento esta sección no esta disponible para teléfonos móviles.
        </Text>
      </View>
    );
  }

  render() {
    if (this.props.renderingMode === APPLICATION_RENDERING_MODE_SCREEN) {
      return (this.getMainView());
    } else if (this.props.renderingMode === APPLICATION_RENDERING_MODE_MOBILE) {
      return (this.getMobileMessage());
    }
  }
}

const mapStateToProps = state => {
  return {
    source: state.external.source,
    error: state.external.error,
    loading: state.external.loading,
    renderingMode: state.application.renderingMode
  };
};

export default connect(mapStateToProps, { requestAccess })(TransfersForm);