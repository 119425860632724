export const ARS = 'ARS';
export const USD = 'USD';
export const USDB = 'USDB';
export const PDF_ACCOUNT_PARAM = 'account';
export const PDF_PORTFOLIO_AGREEMENT_DATE_PARAM = 'portfolioAgreementDate';
export const PDF_PORTFOLIO_CURRENCY_PARAM = 'portfolioCurrency';
export const PDF_PERFORMANCE_DATE_FROM_PARAM = 'performanceDateFrom';
export const PDF_PERFORMANCE_DATE_TO_PARAM = 'performanceDateTo';
export const PDF_PERFORMANCE_CURRENCY_PARAM = 'performanceCurrency';
export const PDF_PORTFOLIO_PARAM = 'portfolio';
export const PDF_PERFORMANCE_PARAM = 'performance';
export const PDF_TRANSACTIONS_PARAM = 'transactions';
export const PDF_TRANSACTIONS_DATE_FROM_PARAM = 'transactionsDateFrom';
export const PDF_TRANSACTIONS_DATE_TO_PARAM = 'transactionsDateTo';
export const REPORT_PORTFOLIO = 'report_portfolio';
export const REPORT_PERFORMANCE = 'report_performance';
export const REPORT_TRANSACTIONS = 'report_transactions';

export const PORTFOLIO_AGREEMENT_DATE = 'portfolioAgreementDate';
export const PORTFOLIO_CURRENCY = 'portfolioCurrency';
export const PERFORMANCE_DATE_FROM = 'performanceDateFrom';
export const PERFORMANCE_DATE_TO = 'performanceDateTo';
export const PERFORMANCE_CURRENCY = 'performanceCurrency';
export const TRANSACTIONS_DATE_FROM = 'transactionsDateFrom';
export const TRANSACTIONS_DATE_TO = 'transactionsDateTo';

export const PDF_ERROR_MESSAGE = 'Ocurrió un error al descargar el informe. Por favor compruebe los campos seleccionados.';
export const PDF_DOWNLOAD_NO_OPTIONS_SELECTED_MESSAGE = 'Debe seleccionar una o más opciones'
export const PDF_ERROR_NO_DATA_MESSAGE = 'No hay datos disponibles para las fechas seleccionadas.';
export const PDF_ERROR_UNEXPECTED_MESSAGE = 'Ocurrió un error al intentar generar el informe para las fechas seleccionadas.';
export const PDF_REPORT_FILENAME = 'ResumenCuenta.pdf';