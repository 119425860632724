import React, { Component } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { SpinnerStyle as style } from '../../styles/common';

class Spinner extends Component {
    render() {
        return(
            <View style={style.containerStyle}>
                <ActivityIndicator 
                    size={this.props.size || 'large'}
                    style={style.spinnerStyle}
                />
            </View>
        );
    }
};

export { Spinner };
