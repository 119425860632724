import React, { Component } from "react";
import { Text, View } from "react-native";
import { ModalStyle as style } from '../../styles/common/SimpleModal';
import { Button } from '../common';
import Modal from 'modal-enhanced-react-native-web';

class SimpleModal extends Component {

  renderCloseButton = () => (
    <Button onPress={this.props.onClose}>
      <Text style={style.buttonText}>
        {this.props.buttonText}
      </Text>
    </Button>
  );

  renderModalContent = () => (
    <View style={style.modalContent}>
      <Text style={style.modalText}>{this.props.text}</Text>
      {this.renderCloseButton()}
    </View>
  );

  render() {
    return (
      <View style={style.container}>
        <Modal isVisible={this.props.showModal}>
          {this.renderModalContent()}
        </Modal>
      </View>
    );
  }
}
export { SimpleModal }